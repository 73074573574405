const DATABASE_NAME = 'driver-app-atlas';
const DATABASE_VERSION = 4;

export const StorageKeys = {
  Shipments: 'shipments',
  Users: 'users',
  Mutations: 'mutations',
  Env: 'env',
  Uploads: 'uploads',
} as const;

export type StorageKey = (typeof StorageKeys)[keyof typeof StorageKeys];

export const StorageIndexes = {
  RelatedEntryKey: 'relatedEntryKey',
  RelatedEntryObjectKey: 'relatedEntryObjectKey',
} as const;

export type StorageIndex = (typeof StorageIndexes)[keyof typeof StorageIndexes];

type ObjectKey<ObjectType> = string | ((object: ObjectType) => string) | ((object: ObjectType) => Promise<string>);

const getObjectKey = async <ObjectType>(object: ObjectType, key: ObjectKey<ObjectType>): Promise<string> => {
  if (typeof key === 'function') {
    return await key(object);
  }
  return key;
};

const promisifyRequest = <RequestType>(request: IDBRequest<RequestType>): Promise<RequestType> => {
  return new Promise((resolve, reject) => {
    request.onerror = () => {
      reject(request.error);
    };
    request.onsuccess = () => {
      resolve(request.result);
    };
  });
};

const getDb = async (): Promise<IDBDatabase> => {
  const request = window.indexedDB.open(DATABASE_NAME, DATABASE_VERSION);
  request.onupgradeneeded = (event) => {
    const { newVersion } = event;
    const db = request.result;
    Object.values(StorageKeys).forEach((key) => {
      try {
        db.createObjectStore(key);
      } catch {
        // This is fine, the object store already exists
      }
    });
    if (newVersion && newVersion >= 4) {
      const storageKeysWithRelatedEntryIndex = [StorageKeys.Mutations, StorageKeys.Uploads];
      storageKeysWithRelatedEntryIndex.forEach((key) => {
        db.deleteObjectStore(key);
        const objectStore = db.createObjectStore(key);
        objectStore.createIndex(StorageIndexes.RelatedEntryKey, 'relatedEntry.key');
        objectStore.createIndex(StorageIndexes.RelatedEntryObjectKey, 'relatedEntry.objectKey');
      });
    }
  };
  const db = await promisifyRequest(request);
  return db;
};

type WithDbCallback<ReturnType> = (db: IDBDatabase) => ReturnType | Promise<ReturnType>;

const withDb = async <ReturnType>(callback: WithDbCallback<ReturnType>): Promise<ReturnType> => {
  const db = await getDb();
  const result = await callback(db);
  db.close();
  return result;
};

export const clearDb = async () => {
  await withDb(async (db) => {
    const storageKeys = Object.values(StorageKeys);
    const transaction = db.transaction(storageKeys, 'readwrite');
    for (const key of storageKeys) {
      const objectStore = transaction.objectStore(key);
      const request = objectStore.clear();
      await promisifyRequest(request);
    }
    transaction.commit();
  });
};

export const putObject = async <ObjectType>(storageKey: StorageKey, value: ObjectType, objectKey) => {
  await withDb(async (db) => {
    const transaction = db.transaction([storageKey], 'readwrite');
    const objectStore = transaction.objectStore(storageKey);
    const key = await getObjectKey(value, objectKey);
    const request = objectStore.put(value, key);
    await promisifyRequest(request);
    transaction.commit();
  });
};

export const replaceObjects = async <ObjectType>(storageKey: StorageKey, values: ObjectType[], objectKey: ObjectKey<ObjectType>) => {
  await withDb(async (db) => {
    const transaction = db.transaction([storageKey], 'readwrite');
    const objectStore = transaction.objectStore(storageKey);
    const request = objectStore.clear();
    await promisifyRequest(request);

    for (const value of values) {
      const key = await getObjectKey(value, objectKey);
      const request = objectStore.add(value, key);
      await promisifyRequest(request);
    }
    transaction.commit();
  });
};

export const getObject = async <ObjectType>(storageKey: StorageKey, objectKey: string): Promise<ObjectType | undefined> => {
  return await withDb(async (db) => {
    const transaction = db.transaction([storageKey], 'readonly');
    const objectStore = transaction.objectStore(storageKey);
    const request = objectStore.get(objectKey);
    const result = await promisifyRequest<ObjectType>(request);
    transaction.commit();
    return result;
  });
};

export const getAllObjects = async <ObjectType>(storageKey: StorageKey): Promise<ObjectType[]> => {
  return await withDb(async (db) => {
    const transaction = db.transaction([storageKey], 'readonly');
    const objectStore = transaction.objectStore(storageKey);
    const request = objectStore.getAll();
    const result = await promisifyRequest<ObjectType[]>(request);
    transaction.commit();
    return result;
  });
};

export const deleteObject = async (storageKey: StorageKey, objectKey: string) => {
  await withDb(async (db) => {
    const transaction = db.transaction([storageKey], 'readwrite');
    const objectStore = transaction.objectStore(storageKey);
    const request = objectStore.delete(objectKey);
    await promisifyRequest(request);
    transaction.commit();
  });
};

export const findObject = async <ObjectType>(storageKey: StorageKey, index: StorageIndex, value: string) => {
  return await withDb(async (db) => {
    const transaction = db.transaction([storageKey], 'readonly');
    const objectStore = transaction.objectStore(storageKey);
    const indexObject = objectStore.index(index);
    const request = indexObject.get(value);
    const result = await promisifyRequest<ObjectType>(request);
    transaction.commit();
    return result;
  });
};
