import type { AxiosError } from 'axios';
import { isNetworkError, type request_input } from 'common/helpers.ts';
import React from 'react';
import useSWR from 'swr';

type Fetcher = (...args: any) => Promise<any>;

type OnUpdateDataInOfflineStorage<ResponseType> = (data: ResponseType) => void;

type GetDataFromOfflineStorage<ResponseType> = () => Promise<ResponseType | undefined>;

export default function useSwrOffline<ResponseType>(
  requestInput: request_input,
  fetcher: Fetcher,
  getDataFromOfflineStorage: GetDataFromOfflineStorage<ResponseType>,
  updateDataInOfflineStorage: OnUpdateDataInOfflineStorage<ResponseType>,
  swrConfig?: any,
) {
  const { data: fetchedData, isLoading, error, mutate } = useSWR<ResponseType, AxiosError>(requestInput, fetcher, swrConfig);
  const [data, setData] = React.useState<ResponseType | undefined>(undefined);

  const networkError = isNetworkError(error);

  const onNetworkError = React.useCallback(async () => {
    const object = await getDataFromOfflineStorage();
    setData(object);
  }, [getDataFromOfflineStorage]);

  React.useEffect(() => {
    // We don't need to replace the object if we already have it
    if (networkError && !data) {
      void onNetworkError();
    }
  }, [networkError, data, onNetworkError]);

  React.useEffect(() => {
    if (fetchedData) {
      setData(fetchedData);
    }
  }, [fetchedData]);

  React.useEffect(() => {
    if (error && !networkError) {
      setData(undefined);
    } else if (data) {
      void updateDataInOfflineStorage?.(data);
    }
  }, [data, updateDataInOfflineStorage, error, networkError]);

  return {
    data,
    isLoading,
    error,
    refresh: mutate,
    networkError,
  };
}
