import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import type { RootState } from '#admin/store.ts';

type Props = {
  ifAuthed: boolean;
  element: React.ReactElement<any, any>;
  elseTo: string;
};

export const AuthGate: React.FC<Props> = ({ ifAuthed, element, elseTo }) => {
  const navigate = useNavigate();
  const token = useSelector((state: RootState) => state.app.token);
  const isAuthenticated = !!token;

  React.useEffect(() => {
    if (ifAuthed && !isAuthenticated) navigate(elseTo);
    else if (!ifAuthed && isAuthenticated) navigate(elseTo);
  }, [ifAuthed, isAuthenticated, elseTo, navigate]);

  if (ifAuthed) {
    if (isAuthenticated) return element;
    else return <Navigate to={elseTo} />;
  } else {
    if (!isAuthenticated) return element;
    else return <Navigate to={elseTo} />;
  }
};
