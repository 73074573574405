import cx from 'clsx';
/**
 * Datetime component renders a datetime string in the provided timezone, or in the browser's timezone if SHIFT key is pressed and hold.
 */
import { dateRangeToTimezonedYMHM, dateToTimezonedYMDHM } from 'common/date.ts';
import { getRuntimeTimezone } from 'common/helpers.ts';
import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

type Props = {
  className?: string;
  layout?: 'x' | 'y';
  timezone: string;
  truncateRegion?: boolean;
  rangeFrom?: string | null;
  children: string | null;
};

export const Datetime: React.FC<Props> = ({ className, layout = 'x', timezone, truncateRegion = false, rangeFrom, children: datetime, ...otherProps }: Props) => {
  const [forceBrowserTimezone, setForceBrowserTimezone] = React.useState(false);

  useHotkeys('SHIFT', () => setForceBrowserTimezone(true), { keydown: true });
  useHotkeys('SHIFT', () => setForceBrowserTimezone(false), { keyup: true });

  const timezoneToUse = forceBrowserTimezone ? getRuntimeTimezone() : timezone;
  const formattedDatetime = datetime ? dateToTimezonedYMDHM(datetime, timezoneToUse) : null;
  const formattedDatetimeRange = rangeFrom ? dateRangeToTimezonedYMHM(rangeFrom, datetime, timezoneToUse) : null;
  const formattedTimezone = truncateRegion ? timezoneToUse.split('/')[1] : timezoneToUse;

  return (
    <span className={cx('flex w-full gap-1', layout === 'x' ? 'flex-row' : 'flex-col', className)} {...otherProps}>
      {formattedDatetime && (
        <React.Fragment>
          <span>{rangeFrom ? formattedDatetimeRange : formattedDatetime}</span>
          <span>({formattedTimezone})</span>
        </React.Fragment>
      )}
      {!formattedDatetime && '-'}
    </span>
  );
};
