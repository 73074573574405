// @ts-ignore: @todo: esnext issue
import { Float } from '@headlessui-float/react';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import type React from 'react';
import { Link } from 'react-router-dom';
import type { Shipment } from 'types/Shipment.ts';
import { IDots, IEye, IUser } from 'ui/component/Icons.tsx';
import useMe from '#admin/hook/useMe.tsx';

type Props = {
  shipment: Shipment;
  onClickDriverAssign: () => void;
};

export const ShipmentListItemMenu: React.FC<Props> = ({ shipment, onClickDriverAssign }) => {
  const me = useMe();
  if (!me) return null;

  const legs = shipment.legs;
  const isMultipleLegs = legs.length > 1;
  const driver = legs[0].driver;

  return (
    <Menu>
      {({ close }) => (
        <Float
          portal={true}
          enter="transition ease-out duration-200"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
          placement="bottom-end"
        >
          <MenuButton className="flex size-full place-content-center place-items-center" data-table-no-row-events>
            <IDots className="text-gray-400" size={28} data-table-no-row-events />
          </MenuButton>
          <MenuItems className="row-menu">
            <div className="p-1">
              <MenuItem>
                <Link className="contents" to={`/shipment/${shipment.id}`}>
                  <button className="row-menu-item">
                    <IEye size={20} />
                    View
                  </button>
                </Link>
              </MenuItem>
              {me.role === 'AGENT' && (
                <>
                  {!isMultipleLegs && (
                    <button
                      onClick={() => {
                        // This can't be outside here (e.g in a handler function) because of the portal
                        close();
                        onClickDriverAssign();
                      }}
                      className="row-menu-item"
                    >
                      <IUser size={20} />
                      {driver ? 'Reassign' : 'Assign'}
                    </button>
                  )}
                  {isMultipleLegs && (
                    <Link className="contents" to={`/shipment/${shipment.id}`}>
                      <button className="row-menu-item">
                        <IUser size={20} />
                        {driver ? 'Reassign' : 'Assign'}
                      </button>
                    </Link>
                  )}
                </>
              )}
            </div>
          </MenuItems>
        </Float>
      )}
    </Menu>
  );
};
