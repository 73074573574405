import { BaseError, errorCodes, errorMessages } from 'common/errors/index.ts';
import { decryptDocument } from 'common/helpers.ts';
import React from 'react';
import type { DocumentInfo } from './useFetchDocuments.ts';

export default function useDocumentDownloadUrl(documentInfo?: DocumentInfo) {
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [isDownloading, setIsDownloading] = React.useState(false);
  const [url, setUrl] = React.useState<string>();

  const revokeUrl = React.useCallback((url: string) => {
    window.URL.revokeObjectURL(url);
  }, []);

  const download = React.useCallback(
    (url: string, revoke = false) => {
      if (!url || !documentInfo) return;
      const { filename } = documentInfo;
      const link = document.createElement('a');
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      if (revoke) {
        revokeUrl(url);
      }
    },
    [documentInfo, revokeUrl],
  );

  const fetchDocument = React.useCallback(
    async (shouldDownload = false) => {
      if (!documentInfo) return;
      const { url, encryptionKey } = documentInfo;

      setErrorMessage('');
      setIsDownloading(true);

      try {
        // 1. Get encryption key, url and filename
        // 2. Download the encrypted document from the url
        const response = await fetch(url);
        if (!response.ok) throw new BaseError(errorCodes.DOCUMENT_DOWNLOAD_FAILED, errorMessages.DOCUMENT_DOWNLOAD_FAILED);
        // 3. Decrypt the document
        const encryptedDocument = await response.arrayBuffer();
        const decryptedDocumentArrayBuffer = await decryptDocument(encryptionKey, encryptedDocument);

        const isPDF = documentInfo.filename.toLowerCase().endsWith('.pdf');
        const blobType = isPDF ? 'application/pdf' : 'application/octet-stream';
        const blob = new Blob([decryptedDocumentArrayBuffer], { type: blobType });
        const urlObject = window.URL.createObjectURL(blob);
        setUrl(urlObject);
        if (shouldDownload) {
          download(urlObject);
        }
        return urlObject;
      } catch (error) {
        console.error(error);
        if (error instanceof Error) setErrorMessage(error.message);
        else setErrorMessage('An error occurred while downloading the document');
      } finally {
        setIsDownloading(false);
      }
    },
    [documentInfo, download],
  );

  return { errorMessage, isDownloading, url, revokeUrl, fetchDocument };
}
