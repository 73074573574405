import type { Auth, BlockchainConnector, OrganisationControl } from 'blockchain/connector.ts';
import Wallet from 'common/blockchain/Wallet.ts';
import { addressToBeSigned, hashStringToHex } from 'common/helpers.ts';

export default class AuthSmartContract {
  public blockchainConnector: BlockchainConnector;
  public authInstance: Auth;
  public organisationId: string;
  public wallet: Wallet;

  constructor(blockchainConnector: BlockchainConnector, organisationId: string) {
    this.blockchainConnector = blockchainConnector;
    this.organisationId = organisationId;
    this.authInstance = blockchainConnector.getAuthInstance();
    this.wallet = new Wallet(blockchainConnector);
  }

  async login(email: string, password: string) {
    const wallet = this.wallet.create();
    const passwordHash = await hashStringToHex(password);
    const tx = await this.authInstance.connect(wallet).login(this.organisationId, email, passwordHash);
    await tx.wait();

    const address = await wallet.getAddress();
    const addressPreparedToBeSigned = addressToBeSigned(address);
    const signature = await wallet.signMessage(addressPreparedToBeSigned);

    return signature;
  }

  async setPassword(userId: string, newPassword: string, passwordResetTokenSignature: string) {
    const { wallet: ephemeralWallet } = this.blockchainConnector.createRandomWallet();
    const newPasswordHash = await hashStringToHex(newPassword);
    const tx = await this.authInstance.connect(ephemeralWallet).setPassword(userId, newPasswordHash, passwordResetTokenSignature);
    await tx.wait();
  }

  async changePassword(newPassword: string) {
    const wallet = this.wallet.get();
    const newPasswordHash = await hashStringToHex(newPassword);
    const tx = await this.authInstance.connect(wallet).changePassword(newPasswordHash);
    await tx.wait();
  }

  async isUserLoggedInWithAddress(user_id: string) {
    const wallet = this.wallet.get();
    const address = await wallet.getAddress();
    return this.authInstance.connect(wallet).isUserLoggedInWithAddress(user_id, address);
  }

  async getUser(id: string) {
    const { wallet: ephemeralWallet } = this.blockchainConnector.createRandomWallet();
    const user = await this.authInstance.connect(ephemeralWallet).getUser(id);
    return user;
  }

  async getOrganization(id: string): Promise<OrganisationControl.OrganisationStructOutput | undefined> {
    const { wallet: ephemeralWallet } = this.blockchainConnector.createRandomWallet();
    const organization = await this.authInstance.connect(ephemeralWallet).getOrganisation(id);
    return organization;
  }
}
