import type { AxiosRequestConfig } from 'axios';
import axios from 'axios';
import type { Vault } from 'blockchain/connector.ts';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime.js';
import type { Alert } from 'types/Alert.ts';
import type { Leg } from 'types/Leg.ts';
import type { SHIPMENT_STATUS, Shipment } from 'types/Shipment.ts';
import type { Point } from 'types/misc.ts';
import { BaseError, errorCodes, errorMessages } from '#common/errors/index.ts';
import type { ShipmentStatusColor } from '#common/types.ts';

dayjs.extend(relativeTime);

export type request_input = any; // [any, any, any?];

export const unique = <T>(value: T, index: number, array: T[]): boolean => array.indexOf(value) === index;

export const uniqueBy =
  <T>(property: keyof T) =>
  (value: T, index: number, array: T[]): boolean =>
    array.findIndex((obj) => obj[property] === value[property]) === index;

export const GET = async ([url, params, transformRequest, transformResponse]: request_input) => {
  const { data } = await axios.get(url, { params: transformRequest ? transformRequest(params) : params });
  const result = transformResponse ? transformResponse(data) : data;
  return result;
};

export const POST = async ([url, params, transformRequest, transformResponse]: request_input) => {
  const { data } = await axios.post(url, transformRequest ? transformRequest(params) : params);
  const result = transformResponse ? transformResponse(data) : data;
  return result;
};

export const DELETE = async ([url, params, transform]: request_input) => {
  const { data } = await axios.delete(url, {
    params,
  });
  const result = transform ? transform(data) : data;
  return result;
};

export const MPOST = async (url, { arg }, config?: AxiosRequestConfig) => {
  let params;

  if (Array.isArray(arg)) {
    const [values, transform] = arg;
    params = transform(values);
  } else {
    params = arg;
  }

  const { data } = await axios.post(url, params, config);
  return data;
};

export const MultipartMPOST = async (url, { arg }, config?: AxiosRequestConfig) => {
  return MPOST(url, { arg }, { headers: { 'Content-Type': 'multipart/form-data' }, ...config });
};

export const MPUT = async (url, { arg }) => {
  let params;

  if (Array.isArray(arg)) {
    const [values, transform] = arg;
    params = transform(values);
  } else {
    params = arg;
  }

  const { data } = await axios.put(url, params);
  return data;
};

// this helper removes null and '' values from an object, and it's useful when dealing with inputs which typically can only hold strings
export const emptyToUndefined = (obj: Record<string, any>) => {
  const result = { ...obj };
  for (const [key, value] of Object.entries(result)) {
    if (value === '' || value === null) delete result[key];
  }
  return result;
};

export const valueToDate = (value): string => {
  if ([undefined, null].includes(value)) return 'N/A';
  const date = new Date(String(value)).toLocaleDateString(undefined, { dateStyle: 'short' });
  return date;
};

export const valueToDatetime = (value): string => {
  if ([null, undefined].includes(value)) return 'N/A';
  const date = new Date(String(value)).toLocaleString(undefined, { dateStyle: 'short', timeStyle: 'short' });
  return date;
};

export const valueToAmount = (value, currency = 'EUR'): string => {
  if ([null, undefined].includes(value)) return 'N/A';

  const amount = new Intl.NumberFormat('it-IT', {
    style: 'currency',
    currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(Number(value));

  return amount;
};

export const valueToNumber = (value, suffix = '') => {
  if ([null, undefined].includes(value)) return 'N/A';

  let amount = new Intl.NumberFormat('it-IT', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(Number(value));

  amount += suffix;

  return amount;
};

export const valueToYESNO = (value: any) => {
  if ([null, undefined].includes(value)) return 'N/A';
  const yesno = Boolean(value) === true ? 'YES' : 'NO';
  return yesno;
};

export const openGoogleMaps = (q: string) => (event) => {
  event.stopPropagation();
  window.open(`https://maps.google.com/?q=${encodeURIComponent(q)}`);
};

export const openURL = (url) => (event) => {
  event.stopPropagation();
  window.open(url);
};

type obj_from_qs = Record<string, string | string[] | boolean>;

export const stringValuesToBoolean = (obj: Record<string, any>) => {
  const result = { ...obj };
  for (const [key, value] of Object.entries(result)) {
    if (value === 'true') result[key] = true;
    else if (value === 'false') result[key] = false;
  }
  return result;
};

export const fromQuerystring = (qs: URLSearchParams, arrayKeys?: string[], excludeKeys?: string[], convertBooleanValues = false): obj_from_qs => {
  let result: obj_from_qs = Object.fromEntries(qs);
  if (convertBooleanValues) result = stringValuesToBoolean(result);
  if (arrayKeys) for (const key of arrayKeys) result[key] = qs.getAll(key);
  if (excludeKeys) for (const key of excludeKeys) delete result[key];
  return result;
};

export const toQuerystring = (obj: obj_from_qs): string => {
  const urlSearchParams = new URLSearchParams();
  for (const [key, value] of Object.entries(obj)) {
    if (!value && value !== false) continue;
    else if (Array.isArray(value)) value.forEach((v) => urlSearchParams.append(key, String(v)));
    else urlSearchParams.set(key, String(value));
  }

  const result = urlSearchParams.toString();
  return result;
};

export const truncate = (text: string, length: number, suffix = '...') => (text.length > length ? text.substring(0, length) + suffix : text);

export const isResponseError = (error: any) => error && axios.isAxiosError(error) && error.response?.status === 400;

export const getResponseError = (error: any, defaultMessage = '') => (isResponseError(error) ? error.response.data : defaultMessage);

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const getRandomString = (length = 10) => Math.random().toString(20).slice(2, length);

export const getFingerprint = (): string => {
  const current_fingerprint = window.localStorage.getItem('atlas:fingerprint');
  if (current_fingerprint) return current_fingerprint;

  const new_fingerprint = window.crypto.getRandomValues(new Uint32Array(1))[0].toString(16);
  window.localStorage.setItem('atlas:fingerprint', new_fingerprint);
  return new_fingerprint;
};

export const stringToPosition = (str: string): Point => {
  const [lon, lat] = str.split(',').map(Number);
  return [lon, lat];
};

export const computeDistance = (point1: Point, point2: Point): number => {
  const [lon1, lat1] = point1.map(Number);
  const [lon2, lat2] = point2.map(Number);
  const R = 6371e3;
  const p1 = (lat1 * Math.PI) / 180;
  const p2 = (lat2 * Math.PI) / 180;
  const deltaLon = lon2 - lon1;
  const deltaLambda = (deltaLon * Math.PI) / 180;
  const d = Math.acos(Math.sin(p1) * Math.sin(p2) + Math.cos(p1) * Math.cos(p2) * Math.cos(deltaLambda)) * R;
  return d;
};

export const getETAStatusMessage = (shipment: Shipment): string => {
  const status = shipment.status;
  const activeLeg = getActiveLeg(shipment);
  const isToBeCollected = shouldBeCollected(activeLeg);
  const isToBeDelivered = shouldBeDelivered(activeLeg);
  if (isToBeCollected) {
    const isLate = dayjs().isAfter(dayjs(activeLeg.origin_expected_datetime));
    return isLate ? 'Ready to pick up' : `Pick up ${dayjs(activeLeg.origin_expected_datetime).fromNow()}`;
  }
  if (isToBeDelivered) {
    const isLate = dayjs().isAfter(dayjs(activeLeg.destination_expected_datetime));
    return isLate ? 'Ready to deliver' : `Deliver ${dayjs(activeLeg.destination_expected_datetime).fromNow()}`;
  }
  return status;
};

export const getStatusColor = (status: SHIPMENT_STATUS): ShipmentStatusColor => {
  const colors: Record<SHIPMENT_STATUS, ShipmentStatusColor> = {
    draft: 'grey',
    pending: 'blue',
    transiting: 'blue',
    delivered: 'green',
    cancelled: 'red',
  };
  return colors[status];
};

export const getAlertsToRead = (alerts: Alert[]) => {
  return alerts.filter((alert) => !alert.archived_at);
};

export const isValidEmail = (value: string) => {
  // Source: https://github.com/colinhacks/zod/blob/3032e240a0c227692bb96eedf240ed493c53f54c/src/types.ts#L600
  const emailRegex = /^(?!\.)(?!.*\.\.)([A-Z0-9_'+\-\.]*)[A-Z0-9_+-]@([A-Z0-9][A-Z0-9\-]*\.)+[A-Z]{2,}$/i;
  return emailRegex.test(value);
};

export const isUUID = (value: string) => value.match(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/);

export const getActiveLeg = (shipment: Shipment) => {
  // We're considering the active leg as the one that has the next address to be reached by the driver, or the last leg if all legs are completed.
  return shipment.legs.find((leg: Leg) => leg.status === 'transiting') || shipment.legs.find((leg: Leg) => leg.status === 'pending') || shipment.legs[shipment.legs.length - 1];
};

export const isShipmentLate = (shipment: Shipment) => {
  // Shipment is late if any leg is delayed (even after delivered)
  for (const leg of shipment.legs) {
    const now = dayjs();
    if (!leg.origin_actual_datetime && now.isAfter(leg.origin_expected_datetime)) return true;
    if (!leg.destination_actual_datetime && now.isAfter(leg.destination_expected_datetime)) return true;
  }
  return false;
};

export const getNextAddress = (shipment: Shipment) => {
  // Returns the next address to be reached by the driver, or the last address if all legs are completed.
  const activeLeg = getActiveLeg(shipment);
  return shouldBeCollected(activeLeg)
    ? `${activeLeg.origin_address_name ? activeLeg.origin_address_name + ' - ' : ''}${activeLeg.origin_address}`
    : `${activeLeg.destination_address_name ? activeLeg.destination_address_name + ' - ' : ''}${activeLeg.destination_address}`;
};

type Position = [number, number];

// Source: https://developers.google.com/maps/documentation/urls/get-started
export const buildDirectionsLink = (position: Position | null, address: string) => {
  const baseUrl = 'https://www.google.com/maps/search/?api=1';
  const query = position ? [...position].reverse().join(',') : address;
  const encodedQuery = encodeURIComponent(query);
  const fullUrl = `${baseUrl}&query=${encodedQuery}`;
  return fullUrl;
};

export const isNetworkError = (error?: unknown) =>
  Boolean(error) && typeof error === 'object' && ((error as { code: string }).code === 'ERR_NETWORK' || (error as { message: string }).message === 'Failed to fetch');

// this helper is useful to handle timeouts in async functions, and you can either provide a default value to resolve on timeout or the error to reject with
export const withTimeout = (fn: any, timeout: number, defaultValueOrError: any) =>
  Promise.race([
    fn,
    new Promise((resolve, reject) =>
      setTimeout(() => {
        if (defaultValueOrError instanceof Error) reject(defaultValueOrError);
        else resolve(defaultValueOrError);
      }, timeout),
    ),
  ]);

export const urlBase64ToUint8Array = (base64String) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replaceAll('-', '+').replace(/_/g, '/');
  const rawData = atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

export const encrypt = async (document: ArrayBuffer): Promise<{ encryptionKey: string; encryptedDocument: Buffer }> => {
  const encryptionKey = await crypto.subtle.generateKey({ name: 'AES-CBC', length: 256 }, true, ['encrypt', 'decrypt']);
  const iv = crypto.getRandomValues(new Uint8Array(16));
  const encryptedDocument = await crypto.subtle.encrypt({ name: 'AES-CBC', iv }, encryptionKey, document);
  const encryptionKeyAsString = Buffer.from(new Uint8Array([...new Uint8Array(await crypto.subtle.exportKey('raw', encryptionKey)), ...iv])).toString('base64');
  return { encryptionKey: encryptionKeyAsString, encryptedDocument: Buffer.from(encryptedDocument) };
};

export const convertStringToArrayBuffer = (str: string) => {
  const textEncoder = new TextEncoder();
  return textEncoder.encode(str).buffer;
};

export const convertBufferToArrayBuffer = (buffer: Buffer) => {
  const arrayBuffer = new ArrayBuffer(buffer.length);
  const view = new Uint8Array(arrayBuffer);
  for (let i = 0; i < buffer.length; ++i) {
    view[i] = buffer[i];
  }
  return arrayBuffer;
};

export const bytesToBase64 = (buffer: ArrayBuffer) => btoa(String.fromCharCode(...new Uint8Array(buffer)));

export const base64ToBytes = (base64: string) => Uint8Array.from(atob(base64), (c) => c.charCodeAt(0)).buffer;

const hexToUint8Array = (hex: string) => {
  if (hex.startsWith('0x')) hex = hex.slice(2);
  const matches = hex.match(/.{1,2}/g);
  if (!matches) throw new Error('Invalid hex string');
  return new Uint8Array(matches.map((byte) => Number.parseInt(byte, 16)));
};

export const uint8ArrayToHex = (bytes: Uint8Array) => {
  let convertedBack = '';
  for (const b of bytes) {
    if (b < 16) convertedBack += '0';
    convertedBack += b.toString(16);
  }
  return convertedBack;
};

export const encryptDocument = async (document: ArrayBuffer): Promise<{ encryptionKey: string; encryptedDocument: ArrayBuffer }> => {
  const encryptionKey = await crypto.subtle.generateKey({ name: 'AES-CBC', length: 256 }, true, ['encrypt', 'decrypt']);
  const iv = crypto.getRandomValues(new Uint8Array(16));
  const encryptedDocument = await crypto.subtle.encrypt({ name: 'AES-CBC', iv }, encryptionKey, document);
  const encryptionKeyAsString = bytesToBase64(new Uint8Array([...iv, ...new Uint8Array(await crypto.subtle.exportKey('raw', encryptionKey))]));
  return { encryptionKey: encryptionKeyAsString, encryptedDocument };
};

export const decryptDocument = async (encryptionKeyBase64: string, encryptedDocument: ArrayBuffer) => {
  const fullEncryptionKey = base64ToBytes(encryptionKeyBase64);
  const iv = fullEncryptionKey.slice(0, 16);
  const encryptionKeyBytes = fullEncryptionKey.slice(16);
  const encryptionKey = await crypto.subtle.importKey('raw', encryptionKeyBytes, 'AES-CBC', true, ['encrypt', 'decrypt']);
  const decryptedDocument = await crypto.subtle.decrypt({ name: 'AES-CBC', iv }, encryptionKey, encryptedDocument);
  return decryptedDocument;
};

export const convertFileToArrayBuffer = (file: File): Promise<ArrayBuffer | null> => {
  return new Promise((resolve, reject) => {
    if (!file || !file.name) {
      reject(new BaseError(errorCodes.FILE_INVALID, errorMessages.FILE_INVALID));
    }
    const reader = new FileReader();
    reader.onload = () => {
      const arrayBuffer: ArrayBuffer | null | string = reader.result;
      if (arrayBuffer === null) {
        resolve(null);
        return;
      }
      if (typeof arrayBuffer === 'string') {
        resolve(convertStringToArrayBuffer(arrayBuffer));
        return;
      }
      if (!arrayBuffer) {
        reject(new BaseError(errorCodes.FILE_FAILED_TO_READ_TO_BUFFER, errorMessages.FILE_FAILED_TO_READ_TO_BUFFER));
        return;
      }
      resolve(arrayBuffer);
    };

    reader.onerror = () => {
      reject(new BaseError(errorCodes.FILE_ERROR_READING, errorMessages.FILE_ERROR_READING));
    };

    reader.readAsArrayBuffer(file);
  });
};

export const getFilenameFromUrl = (url: string): string | null => {
  try {
    const { pathname } = new URL(url);
    const result = pathname.slice(1).split('/').slice(1).join('/');
    return result;
  } catch {
    return null;
  }
};

export const computeHash = async (document: ArrayBuffer): Promise<string> => {
  const buffer = new Uint8Array(document);
  const hashArrayBuffer = await crypto.subtle.digest('SHA-256', buffer);
  const hash = uint8ArrayToHex(new Uint8Array(hashArrayBuffer));
  return hash;
};

export const addPrefix = (str: string, prefix: string) => (str.startsWith(prefix) ? str : `${prefix}${str}`);

export const addHexPrefix = (hex: string) => addPrefix(hex, '0x');

export const zeroPadBytes = (bytes: Uint8Array, length: number) => {
  if (length < bytes.length) throw new BaseError(errorCodes.PADDING_EXCEEDS_DATA_LENGTH, errorMessages.PADDING_EXCEEDS_DATA_LENGTH);
  const result = new Uint8Array(length);
  result.fill(0);
  result.set(bytes, 0);
  return result;
};

export const addressToBeSigned = (address: string) => {
  const addressAsBytes = hexToUint8Array(address);
  const paddedAddress = zeroPadBytes(addressAsBytes, 32);
  return paddedAddress;
};

export const hashStringToUint8Array = async (string: string) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(string);
  const hash = await crypto.subtle.digest('SHA-256', data);
  return new Uint8Array(hash);
};

export const bytesToHex = (arrayBuffer: ArrayBuffer | Uint8Array) => {
  if (typeof arrayBuffer !== 'object' || arrayBuffer === null || typeof arrayBuffer.byteLength !== 'number') {
    throw new TypeError('Expected input to be an ArrayBuffer');
  }

  const view = new Uint8Array(arrayBuffer);
  let result = '';

  for (const value of view) {
    result += value.toString(16).padStart(2, '0');
  }

  return result;
};

export const hashStringToHex = async (string: string) => {
  const hash = await hashStringToUint8Array(string);
  return bytesToHex(hash);
};

export const parseDocumentInfo = (document: Vault.DocumentStructOutput) => {
  const metadata: Record<string, string> = {};
  for (const { key, value } of document.metadata) metadata[key] = value;
  const filename = metadata.filename ?? 'UNNAMED';
  const type = metadata.type ?? 'generic';
  const info = { filename, url: document.url, encryptionKey: document.encryptionKey, metadata, type, originalUrl: document.url };
  return info;
};

export const shouldBeCollected = (leg?: Leg | null) => leg?.status === 'pending';
export const shouldBeDelivered = (leg?: Leg | null) => leg?.status === 'transiting';

export const typeGuard = <T>(value: T | undefined): value is T => value !== undefined;

export const removeEmptyLines = (strings: TemplateStringsArray, ...values: any[]) => {
  const fullstring = strings.reduce((result, string, i) => result + string + (values[i] || ''), '');
  const result = fullstring
    .split('\n')
    .filter((line) => line.trim() !== '')
    .join('\n');
  return result;
};

// we only allow win32 valid filenames to be used so the user can download and have the files named correctly
export const sanitizeFilename = (filename: string): string => {
  // const INVALID_CHARACTERS_REGEX = /[<>:"/\\()|?!+*]/g;
  const INVALID_CHARACTERS_REGEX = /[^a-zA-Z0-9. ]/g;
  const MAX_FILENAME_LENGTH = 50;

  // separate name and extension
  const extensionIndex = filename.lastIndexOf('.');
  const hasExtension = extensionIndex > 0 && extensionIndex < filename.length - 1;
  const name = hasExtension ? filename.slice(0, extensionIndex) : filename;
  const extension = hasExtension ? filename.slice(extensionIndex) : '';

  // remove invalid characters
  let sanitized = name.replace(INVALID_CHARACTERS_REGEX, '_');
  // replace sequence of spaces and underscores
  sanitized = sanitized.replace(/\s+/g, '_').replace(/_+/g, ' ');
  // trim to max length
  if (sanitized.length > MAX_FILENAME_LENGTH) sanitized = sanitized.slice(0, MAX_FILENAME_LENGTH);
  // add extension back
  if (extension) sanitized += extension;

  return sanitized;
};

export const statusToFill = (status: string) => {
  const statusToFillMap = {
    pending: 'fill-grey-light',
    transiting: 'fill-grey-light',
    delivered: 'fill-blue',
  };
  const result = statusToFillMap[status] || 'fill-grey-light';
  return result;
};

export const getRuntimeTimezone = (truncate = false) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (!truncate) return timezone;
  const [, city] = timezone.split('/');
  return city;
};

export const truncateToDecimals = (value: number, decimals = 5) => {
  const factor = 10 ** decimals;
  const result = Math.trunc(value * factor) / factor;
  return result;
};
