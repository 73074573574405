import type { Provider, Signer, TransactionRequest } from 'ethers';
import { JsonRpcProvider } from 'ethers';

const SIGN_METHODS = ['eth_getTransactionByHash', 'eth_getBalance', 'eth_getTransactionCount', 'eth_getProof', 'eth_getTransactionReceipt', 'eth_call'];

const NO_VALID_ADDRESS_CODE = -23026;

class EthereumError extends Error {
  code: any;
  data: any;
  constructor(message: any, code: any, data: any) {
    super(message);
    this.code = code;
    this.data = data;
  }
}

// SDRPCProvider.ts is used both in backend and frontend, so we need to determine the environment to fetch the env from the proper global object
const getAppEnv = () => {
  // we are on the server
  if (typeof window === 'undefined') {
    const APP_ENV = process.env.APP_ENV || 'local';
    return APP_ENV;
  }
  // we are in the browser
  else {
    // @ts-ignore
    const APP_ENV = import.meta?.env?.VITE_APP_ENV || 'local';
    return APP_ENV;
  }
};

export default class SDRPCProvider extends JsonRpcProvider implements Provider {
  private requestId = 0;
  public rpcUrl: string;
  private signer: Signer | null = null;
  private headers: Record<string, string> = {};
  public signMethods: string[] = [];

  constructor(rpcUrl: string) {
    const APP_ENV = getAppEnv();
    // locally we are running a testing geth image with a block time of 1s to have faster transactions thus faster integration tests
    super(rpcUrl, { name: 'sdlite', chainId: 51966 }, { pollingInterval: APP_ENV === 'local' ? 100 : 4_000 });
    this.rpcUrl = rpcUrl;
    this.signMethods = [...SIGN_METHODS];
  }

  setSigner(signer: Signer) {
    this.signer = signer;
  }

  setHeaders(headers: Record<string, string>) {
    this.headers = headers;
  }

  // tofix: this is a temporary fix for wrong gas estimation (besu)
  async estimateGas(_tx: TransactionRequest): Promise<bigint> {
    const estimate = await super.estimateGas(_tx);
    return Promise.resolve((estimate * 110n) / 100n);
  }

  async send(method: string, params: unknown[]): Promise<unknown> {
    // _start must be called as it is recommended in the parent class
    // fetching events does not work without calling it
    await this._start();

    const id = ++this.requestId;
    const payload = {
      jsonrpc: '2.0',
      id,
      method,
      params,
    };

    if (this.signMethods.includes(method)) {
      const signer = this.signer;
      if (signer) {
        const xTimestamp = new Date().toISOString();
        const serialRequest = JSON.stringify(payload);
        const xMessage = serialRequest + xTimestamp;
        const xSignature = await signer.signMessage(xMessage);
        this.setHeaders({
          'x-timestamp': xTimestamp,
          'x-signature': xSignature,
        });
      } else {
        this.setHeaders({});
      }
    } else {
      this.setHeaders({});
    }

    const response = await fetch(this.rpcUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...this.headers,
      },
      body: JSON.stringify(payload),
    });

    const data = await response.json();

    if (response.ok && !data.error) {
      return data.result;
    } else {
      // methods not whitelisted
      if (['eth_getBlockByNumber', 'eth_gasPrice', 'eth_maxPriorityFeePerGas'].includes(method)) {
        return;
      }
      // error while waiting for the transaction to be mined
      if (['eth_getTransactionByHash', 'eth_getTransactionReceipt'].includes(method) && data.error?.code === NO_VALID_ADDRESS_CODE) {
        return;
      }

      if (data.error?.code && data.error.message && data.error.data) {
        throw new EthereumError(data.error.message, data.error.code, data.error.data);
      }

      throw new Error(JSON.stringify(data.error));
    }
  }
}
