import type { ColumnDef, SortingState } from '@tanstack/react-table';
import cx from 'clsx';
import { POST, emptyToUndefined, fromQuerystring, toQuerystring } from 'common/helpers.ts';
import { isEqual } from 'es-toolkit';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useSearchParams } from 'react-router-dom';
import useSWR from 'swr';
import useSWRInfinite from 'swr/infinite';
import type { Tracker } from 'types/Tracker.ts';
import { Button } from 'ui/component/Button.tsx';
import { ISearch } from 'ui/component/Icons.tsx';
import { Metric } from 'ui/component/Metric.tsx';
import { Input } from 'ui/control/Input.tsx';
import { Datefrom } from '#admin/component/Datefrom.tsx';
import { Table, getSWRKeyForPage } from '#admin/component/Table.tsx';
import { TrackerListItemMenu } from '#admin/component/TrackerListItemMenu.tsx';

/** Columns definition **/
const columns: ColumnDef<Tracker>[] = [
  {
    accessorKey: 'metrics_ex_id',
    header: 'ID',
    size: 75,
    cell: (info) => {
      const value = info.getValue<string>();
      if (!value) return '-';
      return (
        <Link className="anchor anchor-novisit" to={`/tracker/${info.row.original.id}`} data-table-no-row-events>
          {value}
        </Link>
      );
    },
  },
  {
    accessorKey: 'description',
    header: 'Serial No.',
    size: 200,
    cell: (info) => {
      const value = info.getValue<string>();
      if (!value) return '-';
      return value;
    },
  },
  {
    header: 'Last measurement',
    accessorKey: 'last_measured_at',
    minSize: 250,
    cell: (info) => {
      const value = info.getValue<string>();
      if (!value) return '-';
      return <Datefrom date={value} aliveInHours={24} />;
    },
  },
  {
    header: 'Battery',
    size: 100,
    accessorKey: 'battery',
    cell: (info) => {
      const value = info.getValue<number>();
      return <Metric type={'battery'} value={value} />;
    },
  },
  {
    header: 'Temperature',
    accessorKey: 'temperature',
    cell: (info) => {
      const value = info.getValue<number>();
      return <Metric type={'temperature'} value={value} />;
    },
  },
  {
    header: 'Humidity',
    accessorKey: 'humidity',
    cell: (info) => {
      const value = info.getValue<number>();
      return <Metric type={'humidity'} value={value} />;
    },
  },
  {
    accessorKey: 'shipment_logistics_ex_id',
    header: 'Order number',
    cell: (info) => {
      const value = info.getValue<string>();
      if (!value) return '-';
      return (
        <Link className="anchor anchor-novisit" to={`/shipment/${info.row.original.shipment_id}`} data-table-no-row-events>
          {value}
        </Link>
      );
    },
  },
  {
    accessorKey: 'package_logistics_ex_id',
    header: 'Package ID',
    cell: (info) => {
      const value = info.getValue<string>();
      return value || '-';
    },
  },
  {
    id: 'actions',
    header: '',
    size: 40,
    meta: { className: '!p-0' },
    cell: (info) => <TrackerListItemMenu row={info.row} />,
  },
];

type FormValues = {
  search: string;
};

const TrackerList = () => {
  const formRef = React.useRef<HTMLFormElement>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showFilters, setShowFilters] = React.useState<boolean>(false);
  const [sorters, setSorters] = React.useState<SortingState>([{ id: 'last_measured_at', desc: false }]);

  const defaultValues: FormValues = { search: '' };
  const defaultValuesWithSearchValues = { ...defaultValues, ...fromQuerystring(searchParams) };
  const [values, setValues] = React.useState<typeof defaultValues>(defaultValuesWithSearchValues);

  const formContext = useForm({
    mode: 'onTouched',
    criteriaMode: 'all',
    shouldUnregister: true,
    shouldUseNativeValidation: false,
    shouldFocusError: true,
    defaultValues: defaultValuesWithSearchValues,
  });
  const { handleSubmit, formState, reset, getValues } = formContext;

  const { data: rowsCount } = useSWR(formState.isValid ? ['/tracker/count', values, emptyToUndefined] : null, POST);
  const swr = useSWRInfinite(getSWRKeyForPage('/tracker/list', 25, rowsCount, values, sorters), POST);

  const toggleFilters = () => setShowFilters(!showFilters);

  const resetFilters = () => {
    reset(defaultValues);
    triggerSubmit();
  };

  // wait next tick or URL will not be updated
  const triggerSubmit = React.useCallback(() => window.setTimeout(() => formRef.current?.requestSubmit(), 50), []);

  const onSubmit = async (values) => {
    setSearchParams(toQuerystring(values), { replace: true });
    setValues(values);
    await swr.mutate();
  };

  // we can't rely only on formState.isDirty because it's true when defaultValues provided to useForm are changed, but we may have filters set by default provided in the URL
  const clearFiltersEnabled = formState.isDirty || !isEqual(defaultValues, getValues());

  return (
    <main className="view-container">
      <section className="flex flex-row place-content-between align-baseline">
        <h4>Trackers</h4>
      </section>

      <section className="mt-4 rounded-2xl bg-white p-8 pb-4">
        <FormProvider {...formContext}>
          <form id="search-form" ref={formRef} className="mb-8 flex flex-col gap-8" noValidate={true} onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            {/* search and button to expand filters section */}
            <div className="flex place-content-between place-items-center">
              <Input
                className="w-[600px]"
                name={'search'}
                placeholder={'Search by Order Number, Package ID, Tracker ID or Tracker Type'}
                required={true}
                autoFocus={true}
                RightIcon={ISearch}
                autoComplete={'off'}
                debouncedAutoSubmitForm={'#search-form'}
                debouncedAutoSubmitTimeout={500}
              />
            </div>

            {/* expanded filters section */}
            <div className={cx('flex flex-col gap-6 rounded-xl bg-blue-sky p-4', !showFilters && 'hidden')}>
              <div className="flex flex-row gap-6" />

              <div className="flex flex-row gap-4">
                <Button className={'blue min-w-max max-w-max'} type="button" onClick={toggleFilters}>
                  Close
                </Button>
                <Button className={'blue ml-auto min-w-max max-w-max'} type="button" onClick={resetFilters} disabled={!clearFiltersEnabled}>
                  Clear filters
                </Button>
                <Button className="blue-outlined min-w-max max-w-max" disabled={showFilters && !formState.isDirty}>
                  Apply
                </Button>
              </div>
            </div>
          </form>
        </FormProvider>

        <Table
          className={cx('max-h-[calc(100vh-320px)]')}
          columns={columns}
          swr={swr}
          pagination={true}
          rowsCount={rowsCount}
          defaultSorting={sorters}
          onColumnSort={setSorters}
          estimateSize={50}
          overscan={50}
        />
      </section>
    </main>
  );
};

export default TrackerList;
