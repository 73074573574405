import { MPOST, POST, truncate } from 'common/helpers.ts';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';
import type { Leg } from 'types/Leg.ts';
import type { Shipment } from 'types/Shipment.ts';
import type { User } from 'types/User.ts';
import { Button } from 'ui/component/Button.tsx';
import { Modal } from 'ui/component/Modal.tsx';
import { Spinner } from 'ui/component/Spinner.tsx';
import { Select } from 'ui/control/Select.tsx';

type Props = {
  shipment: Shipment;
  leg: Leg;
  onSave?: (data: { driver_id: string; leg_id: string }) => void;
  onClose: () => void;
  isOpen: boolean;
};

export const DriverAssignmentDialog: React.FC<Props> = ({ shipment, leg, onSave, onClose, isOpen }) => {
  const { data: drivers, mutate: mutateDrivers, isLoading } = useSWR<User[]>(['/user/list', { role: 'DRIVER', sort: [['name', 'asc']] }], POST);

  const validDrivers = React.useMemo(() => {
    const validDrivers = { none: 'None' };
    if (!drivers) return validDrivers;
    for (const driver of drivers) {
      const name = driver.name.toUpperCase();
      const id = truncate(driver.id, 8, '').toUpperCase();
      validDrivers[driver.id] = `${name} - ${id}`;
    }
    return validDrivers;
  }, [drivers]);

  const formMethods = useForm({
    mode: 'onTouched',
    criteriaMode: 'all',
    shouldUnregister: true,
    shouldUseNativeValidation: false,
    shouldFocusError: true,
    defaultValues: {
      driverId: leg.driver_id || 'none',
    },
  });

  const { handleSubmit, formState, watch } = formMethods;
  const selectedId = watch('driverId');
  const { trigger, isMutating, error } = useSWRMutation(selectedId === 'none' ? `/leg/${leg.id}/unassign-driver` : `/leg/${leg.id}/assign-driver`, MPOST);

  const onSubmit = async ({ driverId }) => {
    if (driverId === 'none' && !window.confirm('Are you sure you want to unassign the driver?')) return;
    if (driverId !== 'none') {
      const targetDriver = drivers?.find((driver) => driver.id === driverId) as User;
      if (!targetDriver || !drivers) return;
    }

    await trigger(selectedId === 'none' ? {} : { driver_id: driverId });
    void mutateDrivers();
    onSave?.({ driver_id: driverId, leg_id: leg.id });
  };

  if (isLoading) return <Spinner centered={true} />;

  return (
    <Modal key={leg.id} title={<h4>Assign Driver</h4>} isOpen={isOpen} onClose={onClose}>
      <FormProvider {...formMethods}>
        <form className="flex w-[450px] flex-col gap-2.5" onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-cols-[100px_min-content] gap-2">
            <span>Order no:</span>
            <span>{shipment.logistics_ex_id}</span>
            <span>Leg ID:</span>
            <span>{leg.logistics_ex_id}</span>
          </div>
          <div className="mt-6 flex flex-col gap-2">
            <Select name={'driverId'} label={'Driver ID'} options={validDrivers} registerOptions={{ required: true }} />
          </div>

          <div className="mt-10 flex gap-5">
            <Button type="button" disabled={isMutating} className="blue-outlined" onClick={onClose}>
              BACK
            </Button>
            <Button
              disabled={formState.isSubmitting || !formState.isValid || !formState.isDirty}
              loading={formState.isSubmitting}
              className="blue flex items-center justify-center"
            >
              Save
            </Button>
          </div>
          {error && (
            <span className="text-center text-red-500">
              {error && typeof error?.response?.data === 'string' ? error.response.data : 'An error occurred while saving. Please try again.'}
            </span>
          )}
        </form>
      </FormProvider>
    </Modal>
  );
};
