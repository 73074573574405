import cx from 'clsx';
import useDocumentDownloadUrl from 'common/hooks/useDocumentDownloadUrl.ts';
import React from 'react';
import { IDownload, IError } from 'ui/component/Icons.tsx';
import { Spinner } from 'ui/component/Spinner.tsx';
import { Tooltip } from 'ui/component/Tooltip.tsx';
import useFetchDocuments from '#admin/hook/useFetchDocuments.js';

type Props = {
  documentUrl: string;
};

export const DocumentDownload: React.FC<Props> = ({ documentUrl }) => {
  // Document info from the Smart Contract
  const documentUrls = React.useMemo(() => [documentUrl], [documentUrl]);
  const { results, isFetching } = useFetchDocuments(documentUrls);
  const [result] = results || [];
  const { info, error: fetchDocumentError } = result || {};
  const { fetchDocument, errorMessage, isDownloading } = useDocumentDownloadUrl(info);

  const handleDownload = () => void fetchDocument(true);

  if (errorMessage || fetchDocumentError?.message) return null;

  if (isFetching) {
    return (
      <div className="relative rounded-xl border border-grey-border px-3 py-2 transition-all ">
        <Spinner size={20} />
      </div>
    );
  }

  return (
    <div
      className={cx('relative rounded-xl border px-3 py-2 transition-all hover:scale-101 hover:cursor-pointer hover:border-blue', {
        'border-grey-border': !isDownloading && !errorMessage,
        'border-red': !isDownloading && errorMessage,
      })}
      onClick={handleDownload}
    >
      <div className="flex flex-row items-center gap-2">
        <span className={cx(errorMessage && 'text-red')}>{info?.filename || 'Download'}</span>
        {!isDownloading && !errorMessage && <IDownload className="ml-auto fill-grey-label" size={24} />}
        {!isDownloading && errorMessage && (
          <Tooltip text={errorMessage}>
            <IError className="fill-red" size={24} />
          </Tooltip>
        )}
        {isDownloading && <Spinner size={20} />}
      </div>
    </div>
  );
};
