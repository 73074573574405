import type { ContractInterface, ContractRunner, Interface, InterfaceAbi, Signer } from 'ethers';
import { BaseContract } from 'ethers';
import type SDRPCProvider from './SDRPCProvider.ts';

class CustomContract extends BaseContract {
  private customProvider: SDRPCProvider | null = null;

  constructor(address: string, fragments: Interface | InterfaceAbi, customProvider: SDRPCProvider, contractRunner?: ContractRunner) {
    super(address, fragments, contractRunner);
    this.customProvider = customProvider;
  }

  connect(runner: Signer | ContractRunner): BaseContract {
    if (this.customProvider && (runner as Signer).signMessage) {
      this.customProvider.setSigner(runner as Signer);
    }
    return super.connect(runner);
  }
}

function _CustomContract(): new (
  address: string,
  fragments: Interface | InterfaceAbi,
  customProvider: SDRPCProvider,
  contractRunner?: ContractRunner,
) => CustomContract & Omit<ContractInterface, keyof BaseContract> {
  return CustomContract as any;
}

export default class SDContract extends _CustomContract() {}
