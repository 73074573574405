import cx from 'clsx';
import { MPOST } from 'common/helpers.ts';
import type { UploadedFile } from 'common/hooks/useUploadFile.ts';
import { signatorEmptyOrValid } from 'common/validators.ts';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import useSWRMutation from 'swr/mutation';
import type { Leg } from 'types/Leg.ts';
import type { Shipment } from 'types/Shipment.ts';
import { Button } from 'ui/component/Button.tsx';
import { IDelete, IFileUpload } from 'ui/component/Icons.tsx';
import { Modal } from 'ui/component/Modal.tsx';
import { Spinner } from 'ui/component/Spinner.tsx';
import { Input } from 'ui/control/Input.tsx';
import { Select } from 'ui/control/Select.tsx';
import useUploadFile from '#admin/hook/useUploadFile.ts';

type Props = {
  shipment: Shipment;
  legs: Leg[];
  onSave?: () => void;
  onClose?: () => void;
  isOpen: boolean;
};

const TYPE_OF_DOCUMENT = {
  poc: 'Proof of Collection (POC)',
  pod: 'Proof of Delivery (POD)',
  generic: 'Generic Document',
};

const MAX_FILE_SIZE_MB = 10;

export const DocumentUploadDialog: React.FC<Props> = ({ shipment, legs, onSave, onClose, isOpen }) => {
  const finishUploadMutation = useSWRMutation('/leg/finish-upload', MPOST);
  const urlMutation = useSWRMutation('/storage/presigned-url', MPOST);

  const firstLeg = legs[0];

  const leg = React.useMemo(() => {
    const leg = legs.length > 1 ? null : legs[0];
    return leg;
  }, [legs]);

  const [isDragging, setIsDragging] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  const isMutating = urlMutation.isMutating || finishUploadMutation.isMutating;

  const formMethods = useForm({
    mode: 'onTouched',
    criteriaMode: 'all',
    shouldUnregister: false, // we need the default values to be applied in all cases
    shouldUseNativeValidation: false,
    shouldFocusError: true,
    defaultValues: {
      type: 'generic',
      signer_name: '',
      document: [],
      datetime: new Date().toISOString(),
    },
  });

  const { handleSubmit, formState, watch, getValues } = formMethods;
  const selectedDocument = watch('document') as File[];
  const [docType, selectedFile] = watch(['type', 'document']) as [keyof typeof TYPE_OF_DOCUMENT, File[]];

  const isDocRequired = docType === 'generic' || (docType === 'poc' && firstLeg.poc_required) || (docType === 'pod' && firstLeg.pod_required);

  const collectDeliverMutation = useSWRMutation(docType === 'poc' ? `/leg/${firstLeg?.id}/collect` : `/leg/${firstLeg?.id}/deliver`, MPOST);

  const addDocument = React.useCallback(
    async ({ url }: UploadedFile) => {
      // If document is POC or POD, update leg status:
      const datetime = getValues('datetime');
      if (docType === 'poc' || docType === 'pod') {
        await collectDeliverMutation.trigger({
          document_url: url,
          datetime,
          manual: true,
        });
      } else {
        await finishUploadMutation.trigger({ document_url: url, leg_ids: legs.map((leg) => leg.id) });
      }
      if (onSave) void onSave();
    },
    [finishUploadMutation, legs, getValues, docType, collectDeliverMutation, onSave],
  );

  const { upload, errorMessage: uploadErrorMessage, isUploading } = useUploadFile({ onSuccess: addDocument });

  const isLoading = isUploading || isMutating;

  const onSubmit = async ({ type, signer_name }) => {
    if (selectedFile.length > 0) {
      await upload({ type, signerName: signer_name, file: selectedDocument[0] });
    } else {
      await collectDeliverMutation.trigger({ datetime: getValues('datetime'), manual: true });
      if (onSave) void onSave();
    }
  };

  React.useEffect(() => {
    if (uploadErrorMessage) {
      setErrorMessage(uploadErrorMessage);
    }
  }, [uploadErrorMessage]);

  const onDropFile = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    const file = files[0];
    const fileSize = file.size / 1024 / 1024; // in MB

    if (fileSize > MAX_FILE_SIZE_MB) {
      setErrorMessage(`File size is too large. Max file size is ${MAX_FILE_SIZE_MB}MB`);
    } else {
      formMethods.setValue('document', Array.from(files));
    }
    setIsDragging(false);
  };

  const dragStateHandler = (isEntering) => (e) => {
    e.preventDefault();
    setIsDragging(isEntering);
  };

  const handleFileInputChange = (e) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      formMethods.setValue('document', Array.from(files));
    }
  };

  const onDeleteFile = () => formMethods.setValue('document', []);

  const handleChooseFile = () => {
    const input = document.getElementById('document');
    if (input) {
      input.click();
    }
  };

  const typeNotUploadedYet = React.useMemo(
    () => (value: string) => {
      if (!leg) return true;
      const doc_already_exists = (value === 'poc' && leg.poc_url) || (value === 'pod' && leg.pod_url);
      return !doc_already_exists;
    },
    [leg],
  );

  const validateSignatorRequired = (value: string) => {
    const errorMessage = 'Signator name is required';
    if (!leg && !value && docType === 'pod') return errorMessage; // Global upload (all legs) should require signer name

    // POD is always required for last leg:
    if (!value && leg?.pod_required && docType === 'pod') {
      return errorMessage;
    }
    return undefined;
  };

  let error: string | null = null;

  if (urlMutation.error?.response?.data) {
    error = urlMutation.error?.response?.data?.message;
  } else if (finishUploadMutation.error?.response?.data) {
    error = finishUploadMutation.error?.response?.data?.message;
  } else if (errorMessage) {
    error = errorMessage;
  }

  return (
    <Modal key={shipment.id} title={<h4>Upload Document</h4>} isOpen={isOpen} onClose={onClose}>
      <FormProvider {...formMethods}>
        <form className="flex max-h-[550px] w-[450px] flex-col gap-2.5 overflow-y-scroll p-2" onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-cols-[100px_min-content] gap-2">
            <span>Order no:</span>
            <span>{shipment.logistics_ex_id}</span>
            <span>Leg ID:</span>
            <span>{leg?.logistics_ex_id || 'All legs'}</span>
          </div>

          {leg && (
            <div className="mt-6 flex flex-col gap-2">
              <Select
                name="type"
                className="h-12"
                label="Type of document"
                disabled={isLoading}
                options={TYPE_OF_DOCUMENT}
                registerOptions={{ required: true, validate: { typeNotUploadedYet } }}
              />
              <div className="red">
                {formState.errors.type?.type === 'required' ? 'Field is required.' : null}
                {formState.errors.type?.type === 'typeNotUploadedYet' ? `There is already a ${docType.toUpperCase()} for this leg.` : null}
              </div>
            </div>
          )}

          {docType !== 'generic' && (
            <>
              <div className="gap- mt-6 flex flex-col">
                <Input
                  name="datetime"
                  label={docType === 'poc' ? 'Collection datetime' : 'Delivery datetime'}
                  type="datetime-local"
                  disabled={isLoading}
                  registerOptions={{ required: true }}
                />
              </div>

              <div className="mt-6 flex flex-col gap-2">
                <Input
                  name="signer_name"
                  label="Signator name"
                  disabled={isLoading}
                  registerOptions={{ required: false, validate: { required: validateSignatorRequired, signatorEmptyOrValid } }}
                />
              </div>
            </>
          )}

          {!isLoading && selectedDocument && selectedDocument.length > 0 && (
            <div className="mt-6 flex items-center gap-3 rounded-xl border p-4 hover:ring-2 hover:ring-blue-500">
              <IFileUpload className="grey" size={32} />
              <span className="flex-1">{selectedDocument[0].name}</span>
              <IDelete size={22} className="red cursor-pointer" onClick={onDeleteFile} />
            </div>
          )}
          <div
            className={cx('mt-6 flex h-32 flex-col items-center justify-center gap-2 rounded-xl border-2 border-dashed py-8', isDragging && 'border-blue-500')}
            onDragEnter={dragStateHandler(true)}
            onDragOver={dragStateHandler(true)}
            onDrop={onDropFile}
            onDragLeave={dragStateHandler(false)}
          >
            {isLoading && <Spinner />}
            {!isLoading && (
              <>
                <label htmlFor="document" className="flex size-full cursor-pointer items-center justify-center gap-4">
                  {!isDragging && (
                    <>
                      <span className="text-blue-500">Drop a file here or </span>
                      <Button type="button" className="blue-outlined w-max" onClick={handleChooseFile}>
                        Choose file
                      </Button>
                    </>
                  )}
                  {isDragging && <IFileUpload className="blue" size={32} />}
                </label>
                <input disabled={isLoading} type="file" name="document" id="document" className="hidden" onChange={handleFileInputChange} />
              </>
            )}
          </div>
          <div className="mt-10 flex gap-5">
            <Button type="button" disabled={isLoading} className="blue-outlined" onClick={onClose}>
              BACK
            </Button>
            <Button
              type="submit"
              disabled={!formState.isValid || (selectedFile.length === 0 && isDocRequired)}
              loading={isLoading}
              className="blue flex items-center justify-center"
            >
              SEND
            </Button>
          </div>
          {error && <span className="text-center text-red-500">{error}</span>}
        </form>
      </FormProvider>
    </Modal>
  );
};
