import type { ColumnDef, SortingState } from '@tanstack/react-table';
import cx from 'clsx';
import { POST, emptyToUndefined, fromQuerystring, toQuerystring, truncate } from 'common/helpers.ts';
import { isEqual } from 'es-toolkit';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useSearchParams } from 'react-router-dom';
import useSWR, { mutate } from 'swr';
import useSWRInfinite from 'swr/infinite';
import type { Alert } from 'types/Alert.ts';
import { Button } from 'ui/component/Button.tsx';
import { ISearch } from 'ui/component/Icons.tsx';
import { Tab } from 'ui/component/Tab.tsx';
import { Input } from 'ui/control/Input.tsx';
import { AlertTag } from '#admin/component/AlertTag.tsx';
import { Datefrom } from '#admin/component/Datefrom.tsx';
import { Table, getSWRKeyForPage } from '#admin/component/Table.tsx';

const getColumns = (onArchive: (id: string, shipmentId: string) => void): ColumnDef<Alert>[] => {
  const columns: ColumnDef<Alert>[] = [
    {
      accessorKey: 'shipment_logistics_ex_id',
      header: 'Order number',
      minSize: 190,
      cell: (info) => {
        const value = info.getValue<string>();
        if (!value) return '-';
        return (
          <Link className="anchor anchor-novisit flex items-center gap-1 uppercase" to={`/shipment/${info.row.original.shipment_id}`}>
            <span className="w-28 overflow-hidden text-ellipsis whitespace-nowrap">{value}</span>
          </Link>
        );
      },
    },
    {
      accessorKey: 'package_logistics_ex_id',
      header: 'Package ID',
      cell: (info) => {
        const value = info.getValue<string>();
        if (!value) return '-';
        return (
          <Link className="anchor anchor-novisit flex items-center gap-1 uppercase" to={`/shipment/${info.row.original.shipment_id}`}>
            {value}
          </Link>
        );
      },
    },
    {
      accessorKey: 'tracker_id',
      header: 'Tracker ID',
      cell: (info) => {
        const value = info.getValue<string>();
        if (!value) return '-';
        return (
          <Link className="anchor anchor-novisit flex items-center gap-1" to={`/tracker/${info.row.original.tracker_id}`}>
            {truncate(value, 8, '')}
          </Link>
        );
      },
    },
    {
      accessorKey: 'leg_id',
      header: 'Leg ID',
      cell: (info) => {
        const value = info.getValue<string>();
        if (!value) return '-';
        return (
          <Link className="anchor anchor-novisit flex items-center gap-1 uppercase" to={`/shipment/${info.row.original.shipment_id}`}>
            {truncate(value, 8, '')}
          </Link>
        );
      },
    },
    {
      header: 'Datetime',
      accessorKey: 'generated_at',
      minSize: 180,
      cell: (info) => {
        const value = info.getValue<string>();
        if (!value) return '-';
        return <Datefrom date={value} />;
      },
    },
    {
      header: 'Alert',
      minSize: 200,
      accessorFn: (row) => row.value,
      enableSorting: false,
      cell: (info) => {
        const alert = info.row.original;
        return <AlertTag alert={alert} />;
      },
    },
    {
      header: 'Value',
      accessorKey: 'value',
      minSize: 100,
      cell: (info) => {
        const value = info.getValue<string>();
        const alert = info.row.original;
        if (!value || alert.type === 'delay') return '-';
        return (
          <span className="truncate">
            {value}
            {info.row.original.unit}
          </span>
        );
      },
    },
    {
      header: 'Threshold',
      accessorFn: (row) => row.value,
      enableSorting: false,
      minSize: 100,
      cell: (info) => {
        const value = info.getValue<string>();
        if (!value) return '-';
        const alert = info.row.original;
        switch (alert.type) {
          case 'battery':
            return (
              <span className="truncate">
                {alert.min || '-'}
                {alert.unit}
              </span>
            );
          case 'delay':
            return '-';
          default:
            return (
              <span className="truncate">
                {alert.min || '-'}
                {alert.unit} / {alert.max || '-'}
                {alert.unit}
              </span>
            );
        }
      },
    },
    {
      header: 'Actions',
      cell: (info) => {
        const id = info.row.original.id;
        const shipmentId = info.row.original.shipment_id;
        const isArchived = info.row.original.archived_at;
        if (!id) return null;
        return (
          <Button disabled={!!isArchived} className="blue" onClick={() => onArchive(id, shipmentId)}>
            Archive
          </Button>
        );
      },
    },
  ];

  return columns;
};

type FormValues = {
  search: string;
  archived: boolean | '';
};

type Props = {
  packageId?: string;
  showColumns?: string[];
  className?: string;
  hideSearch?: boolean;
};

const Alerts: React.FC<Props> = ({ packageId, className, showColumns, hideSearch = false }) => {
  const formRef = React.useRef<HTMLFormElement>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showFilters, setShowFilters] = React.useState<boolean>(false);
  const [sorters, setSorters] = React.useState<SortingState>([{ id: 'generated_at', desc: true }]);

  const defaultValues: FormValues = { search: '', archived: false };
  const defaultValuesWithSearchValues = {
    ...defaultValues,
    ...fromQuerystring(searchParams, undefined, undefined, true),
    ...(packageId ? { package_id: packageId } : {}),
  };
  const [values, setValues] = React.useState<FormValues>(defaultValuesWithSearchValues);

  const formContext = useForm({
    mode: 'onTouched',
    criteriaMode: 'all',
    shouldUnregister: true,
    shouldUseNativeValidation: false,
    shouldFocusError: true,
    defaultValues: defaultValuesWithSearchValues,
  });
  const { handleSubmit, formState, reset, getValues } = formContext;

  const { data: rowsCount } = useSWR(formState.isValid ? ['/alert/count', values, emptyToUndefined] : null, POST);
  const swr = useSWRInfinite(getSWRKeyForPage('/alert/list', 25, rowsCount, values, sorters), POST);

  const toggleFilters = () => setShowFilters(!showFilters);

  const resetFilters = () => {
    reset(defaultValues);
    triggerSubmit();
  };

  // wait next tick or URL will not be updated
  const triggerSubmit = React.useCallback(() => window.setTimeout(() => formRef.current?.requestSubmit(), 50), []);

  const onSubmit = (values: FormValues) => {
    setSearchParams(toQuerystring(values), { replace: true });
    setValues(values);
  };

  const onChangeTab = React.useCallback(
    (nextTab: string | number) => {
      const archived = String(nextTab) === 'yes';
      formContext.setValue('archived', archived);
      triggerSubmit();
    },
    [formContext, triggerSubmit],
  );

  const onArchive = React.useCallback(
    async (id: string, shipmentId: string) => {
      await POST([`/alert/${id}/archive`]);
      void swr.mutate();
      void mutate((key: string[]) => Array.isArray(key) && key[0] === '/alert/count');
      void mutate([`/shipment/${shipmentId}`]);
    },
    [swr],
  );

  const columns = React.useMemo(() => getColumns(onArchive), [onArchive]);

  const defaultTab = getValues('archived') || false;

  // we can't rely only on formState.isDirty because it's true when defaultValues provided to useForm are changed, but we may have filters set by default provided in the URL
  const clearFiltersEnabled = formState.isDirty || !isEqual(defaultValues, getValues());

  return (
    <section className={cx('paper !p-8 rounded-2xl bg-white', className)}>
      <Tab tabs={{ no: 'Active', yes: 'Archived' }} defaultTab={defaultTab ? 'yes' : 'no'} onChange={onChangeTab} />
      <FormProvider {...formContext}>
        <form
          id="search-form"
          ref={formRef}
          className={cx('flex flex-col gap-8', hideSearch ? 'my-4' : 'my-8')}
          noValidate={true}
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
        >
          {/* search and button to expand filters section */}
          {!hideSearch && (
            <>
              <div className="flex place-content-between place-items-center">
                <Input
                  className="w-[500px]"
                  name={'search'}
                  placeholder={'Search by Order Number, Package ID or Tracker ID'}
                  required={true}
                  autoFocus={true}
                  RightIcon={ISearch}
                  autoComplete={'off'}
                  debouncedAutoSubmitForm={'#search-form'}
                  debouncedAutoSubmitTimeout={500}
                />
              </div>
              {/* expanded filters section */}
              <div className={cx('flex flex-col gap-6 rounded-xl bg-blue-sky p-4', !showFilters && 'hidden')}>
                <div className="flex flex-row gap-6" />
                <div className="flex flex-row gap-4">
                  <Button className={'blue min-w-max max-w-max'} type="button" onClick={toggleFilters}>
                    Close
                  </Button>
                  <Button className={'blue ml-auto min-w-max max-w-max'} type="button" onClick={resetFilters} disabled={!clearFiltersEnabled}>
                    Clear filters
                  </Button>
                  <Button className="blue-outlined min-w-max max-w-max" disabled={showFilters && !formState.isDirty}>
                    Apply
                  </Button>
                </div>
              </div>
            </>
          )}
        </form>
      </FormProvider>

      <Table
        className={cx('max-h-[calc(100vh-395px)]')}
        columns={showColumns ? columns?.filter((column) => column.header && showColumns.includes(column.header.toString())) : columns}
        swr={swr}
        pagination={true}
        rowsCount={rowsCount}
        defaultSorting={sorters}
        onColumnSort={setSorters}
        estimateSize={50}
        overscan={50}
      />
    </section>
  );
};

export default Alerts;
