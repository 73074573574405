import { MPOST, getResponseError } from 'common/helpers.ts';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import useSWRMutation from 'swr/mutation';
import type { APP_NAME } from 'types/misc.ts';
import { Button } from 'ui/component/Button.tsx';
import { Input } from 'ui/control/Input.tsx';

type FormValues = {
  email: string;
};

type ResetPasswordRequestValues = FormValues & { app: APP_NAME };

const PasswordRequest: React.FC = () => {
  const [isRequestSent, setIsRequestSent] = React.useState(false);

  const formMethods = useForm<FormValues>({
    mode: 'onTouched',
    criteriaMode: 'all',
    shouldUnregister: true,
    shouldUseNativeValidation: false,
    shouldFocusError: true,
    defaultValues: { email: '' },
  });
  const { handleSubmit, formState } = formMethods;

  const { trigger, isMutating, error } = useSWRMutation<FormValues, any, string, ResetPasswordRequestValues>('/password/request', MPOST);

  const onSubmit = async (values: FormValues) => {
    await trigger({ ...values, app: 'admin' });
    setIsRequestSent(true);
  };

  return (
    <main
      className="fixed top-0 h-screen w-screen overflow-auto pt-16"
      style={{ background: 'linear-gradient(111.34deg, #00CBE0 0%, #006BFA 33.33%, #1B1CDD 66.67%, #640BED 100%)' }}
    >
      <FormProvider {...formMethods}>
        <form className="paper m-auto w-[480px] rounded-xl border" autoCorrect={'off'} autoComplete={'off'} onSubmit={handleSubmit(onSubmit)} noValidate={true}>
          <div className="flex flex-col items-center gap-4 p-8">
            <div className="mb-6 flex w-full items-center justify-center">
              <img src={'/atlas-path-logo.svg'} width={300} />
            </div>
            <h1 className="text-center font-bold text-2xl">Recover Password</h1>
            <p className="body1 mb-4 inline text-center">
              {isRequestSent
                ? 'If we have a user account associated with that email address on record, a reset password link was sent.'
                : 'Please enter your email address and a verification link will be sent to you.'}
            </p>
            <div className="flex w-full flex-col items-center gap-4">
              {!isRequestSent && (
                <>
                  <Input
                    className="w-full"
                    name={'email'}
                    label={'Email address'}
                    autoFocus={true}
                    type={'email'}
                    registerOptions={{
                      validate: { required: (value) => (value ? undefined : "Email can't be empty.") },
                    }}
                  />
                  {error && (
                    <section className="mt-4 grid grid-flow-col justify-center text-center text-red-700">
                      {getResponseError(error, 'An error happened while trying to request the password reset')}
                    </section>
                  )}
                  <Button className="blue mt-4 gap-1" type={'submit'} disabled={!formState.isValid} loading={isMutating}>
                    SUBMIT
                  </Button>
                </>
              )}
              {isRequestSent && (
                <Link to="/auth" className="contents">
                  <Button className="blue mt-4 gap-1">BACK TO LOGIN</Button>
                </Link>
              )}
            </div>
            {!isRequestSent && (
              <Link to="/auth" className="contents">
                <Button className="text-blue">BACK TO LOGIN</Button>
              </Link>
            )}
          </div>
        </form>
      </FormProvider>
    </main>
  );
};

export default PasswordRequest;
