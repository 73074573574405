import cx from 'clsx';
import { MPOST, stringToPosition } from 'common/helpers.ts';
import React from 'react';
import { Link } from 'react-router-dom';
import { mutate } from 'swr';
import useSWRMutation from 'swr/mutation';
import type { Tracker } from 'types/Tracker.ts';
import { Button } from 'ui/component/Button.tsx';
import { IOpenLink, IRefresh } from 'ui/component/Icons.tsx';
import { Metric } from 'ui/component/Metric.tsx';
import { Datefrom } from './Datefrom.tsx';

type Props = {
  tracker: Tracker;
  className?: string;
};

export const TrackerDetails: React.FC<Props> = ({ tracker, className }) => {
  const { trigger, isMutating } = useSWRMutation(`/tracker/${tracker.id}/pull`, MPOST);

  const pullData = React.useCallback(async () => {
    await trigger();
    void mutate([`/tracker/${tracker.id}`]);
  }, [trigger, tracker.id]);

  return (
    <section className={cx('paper flex flex-col gap-4', className)}>
      <Detail label={'Tracker ID'}>
        <strong>{tracker.metrics_ex_id}</strong>
      </Detail>
      <Detail label={'Serial No.'}>{tracker.description || '-'}</Detail>
      <Detail label={'Last measurement'}>{tracker.last_measured_at ? <Datefrom date={tracker.last_measured_at} /> : 'N/A'}</Detail>
      <Detail label={'Battery'}>
        <Metric type={'battery'} value={tracker.battery} />
      </Detail>
      <Detail label={'Temperature'}>
        <Metric type={'temperature'} value={tracker.temperature} />
      </Detail>
      <Detail label={'Humidity'}>
        <Metric type={'humidity'} value={tracker.humidity} />
      </Detail>
      <Detail label={'Position'}>
        <Metric type={'position'} value={tracker.position ? stringToPosition(tracker.position) : tracker.position} />
      </Detail>
      <Detail label={'Order number'}>
        {tracker.shipment_id && (
          <Link className="anchor anchor-novisit" to={`/shipment/${tracker.shipment_id}`}>
            {tracker.shipment_logistics_ex_id || '-'}
          </Link>
        )}
        {!tracker.shipment_id && '-'}
      </Detail>
      <Detail label={'Package ID'}>{tracker.package_logistics_ex_id || '-'}</Detail>

      <hr />

      <div className="flex flex-row gap-3">
        <Link className="contents" to={`https://tracking.tec4med.com/device/${tracker.metrics_ex_id}`} target="_blank" rel="noreferrer">
          <Button className="blue-outlined max-w-max" LeftIcon={IOpenLink}>
            View on Tec4Cloud
          </Button>
        </Link>

        <Button className="blue-outlined max-w-max" LeftIcon={IRefresh} onClick={pullData} loading={isMutating}>
          Pull data
        </Button>
      </div>
    </section>
  );
};

type DetailsProps = {
  label: string;
  children: React.ReactNode;
};

const Detail: React.FC<DetailsProps> = ({ label, children }: DetailsProps) => (
  <span className="body1 flex flex-row items-start gap-16">
    <span className="w-48 text-grey-label">{label}</span>
    <span className="">{children}</span>
  </span>
);
