import type { BlockchainConnector } from 'blockchain/connector.ts';
import type { Vault } from 'blockchain/connector.ts';
import Wallet from 'common/blockchain/Wallet.ts';
import { addHexPrefix } from 'common/helpers.ts';

export default class VaultSmartContract {
  public blockchainConnector: BlockchainConnector;
  public vaultInstance: Vault;
  public organisationId: string;
  public wallet: Wallet;

  constructor(blockchainConnector: BlockchainConnector) {
    this.blockchainConnector = blockchainConnector;
    this.vaultInstance = blockchainConnector.getVaultInstance();
    this.wallet = new Wallet(blockchainConnector);
  }

  async createDocuments(documents: Vault.DocumentParamsStruct[]) {
    const wallet = this.wallet.get();
    const tx = await this.vaultInstance.connect(wallet).createDocuments(documents);
    await tx.wait();
  }

  async createDocumentAndPermissions(document: Vault.DocumentParamsStruct, permissions: string[]) {
    const wallet = this.wallet.get();
    const { vaultInstance } = this;

    const tx = await vaultInstance
      .connect(wallet)
      .multicall([
        vaultInstance.interface.encodeFunctionData('createDocuments', [[document]]),
        vaultInstance.interface.encodeFunctionData('transferDocumentOwnership', [document.hash, permissions[0]]),
        vaultInstance.interface.encodeFunctionData('shareDocuments', [[document.hash], permissions]),
      ]);

    await tx.wait();
  }

  async updateDocumentMetadata(documentHash: string, metadata: { key: string; value: string }[]) {
    const wallet = this.wallet.get();
    const tx = await this.vaultInstance.connect(wallet).updateDocumentMetadata(addHexPrefix(documentHash), metadata);
    await tx.wait();
  }

  async getDocument(documentHash: string) {
    const wallet = this.wallet.get();
    const result = this.vaultInstance.connect(wallet).getDocument(addHexPrefix(documentHash));
    return result;
  }

  async verifyDocument(documentHash: string) {
    const { wallet: ephemeralWallet } = this.blockchainConnector.createRandomWallet();
    const result = this.vaultInstance.connect(ephemeralWallet).verifyDocument(addHexPrefix(documentHash));
    return result;
  }
}
