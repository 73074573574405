import React from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '#driver/reducer/app.ts';

export default function useLogout() {
  const dispatch = useDispatch();

  return React.useCallback(async () => {
    dispatch(actions.logout());
  }, [dispatch]);
}
