import type { TransactionRequest, TransactionResponse } from 'ethers';
import { sleep } from '../utils/helpers.ts';
import { _NonceManager } from './_NonceManager.ts';
import { sendTransactionAndRetry } from './sendTransactionAndRetry.ts';

export default class NonceManager extends _NonceManager {
  static readonly retries = 5;
  static readonly waitTime = 4_000;

  async sendTransaction(tx: TransactionRequest, retries = NonceManager.retries): Promise<TransactionResponse> {
    if (!this.provider) throw new Error('Provider not set');

    const beforeRetry = async () => {
      await sleep(NonceManager.waitTime);
      this.reset();
    };

    const sendTransaction = async () => {
      return await super.sendTransaction(tx);
    };

    return await sendTransactionAndRetry(await this.getAddress(), sendTransaction.bind(this), this.provider, { retries, beforeRetry });
  }
}
