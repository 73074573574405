import cx from 'clsx';
import React from 'react';
import type { LEG_STATUS } from 'types/Leg.ts';
import { ITruck } from './Icons.tsx';

export const STEP_STATUES = ['crossed', 'pending', 'current', 'waiting'] as const;
export type STEP_STATUS = (typeof STEP_STATUES)[number];

type Props = {
  className?: string;
  steps: string[];
  currentStep: string;
  onStepClick?: (x: string) => void;
  currentLegStatus?: LEG_STATUS;
};

export const Stepper: React.FC<Props> = ({ className, steps, currentStep, onStepClick, currentLegStatus }) => {
  const currentStepIndex = steps.indexOf(currentStep);
  const stepIsCrossed = React.useCallback((step: string) => steps.indexOf(step) <= steps.indexOf(currentStep), [steps, currentStep]);
  const stepIsPending = React.useCallback((step: string) => step === currentStep && currentLegStatus === 'pending', [currentStep, currentLegStatus]);
  const stepIsCurrent = React.useCallback((step: string) => step === currentStep && currentLegStatus === 'transiting', [currentStep, currentLegStatus]);
  const stepIsWaiting = React.useCallback((step: string) => steps.indexOf(step) > steps.indexOf(currentStep), [steps, currentStep]);

  const onClick = (step: string) => () => {
    if (!onStepClick) return;
    onStepClick(step);
  };

  // const computeStepStatus = React.useCallback(
  //   (step: string): STEP_STATUS => {
  //     if (stepIsPending(step)) return 'pending';
  //     if (stepIsCurrent(step)) return 'current';
  //     if (stepIsCrossed(step)) return 'crossed';
  //     if (stepIsWaiting(step)) return 'waiting';
  //     return 'waiting';
  //   },
  //   [stepIsPending, stepIsCurrent, stepIsCrossed, stepIsWaiting],
  // );

  return (
    <ul className={cx('relative flex flex-row justify-items-start', className)}>
      {steps.map((step, index) => (
        <li key={index} className={cx('shrink basis-0', index !== 0 && 'flex-1', index === steps.length - 1 && 'justify-end')}>
          <div className="inline-flex min-h-7 w-full min-w-7 select-none items-center align-middle text-xs">
            <div
              className={cx(
                'relative h-1 w-full flex-1 text-white hover:border-blue-500 hover:outline-none hover:ring',
                onStepClick && 'cursor-pointer',
                stepIsCrossed(step) && 'bg-blue',
                stepIsPending(step) && 'bg-blue',
                stepIsCurrent(step) && 'bg-grey-aqua',
                stepIsWaiting(step) && 'bg-grey-light',
              )}
              onClick={onClick(step)}
            >
              {currentLegStatus === 'transiting' && steps.includes(currentStep) && index === currentStepIndex + 1 && (
                <>
                  <div
                    className={cx(
                      '-translate-y-1/2 absolute top-1/2 h-1 w-1/2',
                      stepIsCrossed(step) && 'bg-blue',
                      stepIsPending(step) && 'bg-blue',
                      stepIsCurrent(step) && 'bg-grey-aqua',
                      stepIsWaiting(step) && 'bg-grey-light',
                    )}
                  />
                  <div className="-translate-x-1/2 -translate-y-1/2 absolute top-1/2 left-1/2 flex items-center justify-center rounded-full bg-[#D9F7FA] p-1">
                    <div className="flex items-center justify-center rounded-full bg-aqua p-2">
                      <ITruck size={16} className="text-white" />
                    </div>
                  </div>
                </>
              )}
            </div>
            <span
              className={cx(
                'flex size-7 shrink-0 place-content-center place-items-center rounded-full text-lg text-white',
                stepIsCrossed(step) && 'bg-blue',
                stepIsPending(step) && (steps.indexOf(step) === 0 ? 'bg-grey-light' : 'bg-grey-blue'), // tofix: exception needed for 1st step when pending
                stepIsCurrent(step) && 'bg-grey-aqua',
                stepIsWaiting(step) && 'bg-grey-light',
              )}
            >
              {step}
            </span>
          </div>
        </li>
      ))}
    </ul>
  );
};
