import type React from 'react';
import type { Package } from 'types/Package.ts';
import type { Shipment } from 'types/Shipment.ts';
import { CopyableText } from 'ui/component/CopyableText.tsx';
import { Modal } from 'ui/component/Modal.tsx';
import useMe from '#admin/hook/useMe.tsx';
import Alerts from './Alerts.tsx';
import { TrackerDetails } from './TrackerDetails.tsx';

type Props = {
  shipment: Shipment;
  pkg: Package;
  onClose?: () => void;
  isOpen: boolean;
};

export const PackageDetails: React.FC<Props> = ({ shipment, pkg, onClose, isOpen }) => {
  const me = useMe();

  return (
    <Modal title={<h4>Package Details</h4>} key={pkg.id} isOpen={isOpen} onClose={onClose}>
      <div className="flex max-h-[600px] w-[900px] flex-col gap-8 overflow-scroll">
        <section>
          <div className="grid grid-cols-[100px_min-content] gap-2">
            <span>Order no:</span>
            <CopyableText copyText={shipment.logistics_ex_id}>#{shipment.logistics_ex_id}</CopyableText>
            <span>Package ID:</span>
            <CopyableText copyText={pkg.logistics_ex_id}>#{pkg.logistics_ex_id}</CopyableText>
            {pkg.description && (
              <>
                <span>Type:</span>
                <CopyableText copyText={pkg.description}>{pkg.description}</CopyableText>
              </>
            )}
          </div>
        </section>
        {pkg.tracker && (
          <section className="flex flex-col gap-4">
            <h4>Tracker</h4>
            <TrackerDetails className="!bg-blue-50" tracker={pkg.tracker} />
          </section>
        )}
        {me?.role === 'ADMIN' && (
          <section className="flex flex-col gap-4 pb-6">
            <h4>Alerts</h4>
            <Alerts hideSearch={true} showColumns={['Tracker ID', 'Datetime', 'Alert', 'Value', 'Actions']} packageId={pkg.id} className="!bg-blue-50" />
          </section>
        )}
      </div>
    </Modal>
  );
};
