import type { ValueOf } from 'common/types.ts';

const ErrorCodes = {
  COMMON_INVALID_INPUT_DATA: 'COMMON_INVALID_INPUT_DATA',
  COMMON_NOT_FOUND: 'COMMON_NOT_FOUND',
  COMMON_UNAUTHORIZED: 'COMMON_UNAUTHORIZED',
  COMMON_INTERNAL_SERVER_ERROR: 'COMMON_INTERNAL_SERVER_ERROR',
  COMMON_SMART_CONTRACT_NOT_FOUND: 'SMART_CONTRACT_NOT_FOUND',
  COMMON_INVALID_VALUE: 'COMMON_INVALID_VALUE',
  ALERT_PACKAGE_ID_OR_LEG_ID_IS_REQUIRED: 'ALERT_PACKAGE_ID_OR_LEG_ID_IS_REQUIRED',
  AUTH_INVALID_CREDENTIALS: 'AUTH_INVALID_CREDENTIALS',
  AUTH_USER_NOT_EXISTS_IN_THE_AUTH: 'AUTH_USER_NOT_EXISTS_IN_THE_AUTH',
  AUTH_USER_IS_LOCKED: 'AUTH_USER_IS_LOCKED',
  AUTH_NO_PERMISSIONS: 'AUTH_NO_PERMISSIONS',
  AUTH_INVALID_SAML_RESPONSE: 'AUTH_INVALID_SAML_RESPONSE',
  AUTH_USER_NOT_FOUND: 'AUTH_USER_NOT_FOUND',
  AUTH_SMART_CONTRACT_NOT_FOUND: 'AUTH_SMART_CONTRACT_NOT_FOUND',
  AUTH_SMART_CONTRACT_USERS_NOT_CREATED: 'AUTH_SMART_CONTRACT_USERS_NOT_CREATED',
  AUTH_SMART_CONTRACT_USERS_NOT_UPDATED: 'AUTH_SMART_CONTRACT_USERS_NOT_UPDATED',
  AUTH_SMART_CONTRACT_USER_NOT_FOUND: 'AUTH_SMART_CONTRACT_USER_NOT_FOUND',
  AUTH_SMART_CONTRACT_PASSWORD_NOT_RECOVERED: 'AUTH_SMART_CONTRACT_PASSWORD_NOT_RECOVERED',
  API_KEY_NOT_FOUND: 'API_KEY_NOT_FOUND',
  API_KEY_INVALID_USER: 'API_KEY_INVALID_USER',
  ASYNC_STORAGE_USER_NOT_FOUND: 'ASYNC_STORAGE_USER_NOT_FOUND',
  CONSIGNEE_NOT_FOUND: 'CONSIGNEE_NOT_FOUND',
  CREATOR_ID_IS_REQUIRED: 'CREATOR_ID_IS_REQUIRED',
  CURRENT_BLOCK_NOT_FOUND: 'CURRENT_BLOCK_NOT_FOUND',
  CUSTOMER_NOT_FOUND: 'CUSTOMER_NOT_FOUND',
  DOCUMENT_DOWNLOAD_FAILED: 'DOCUMENT_DOWNLOAD_FAILED',
  DOCUMENT_NOT_FOUND: 'DOCUMENT_NOT_FOUND',
  DOCUMENT_URL_IS_REQUIRED: 'DOCUMENT_URL_IS_REQUIRED',
  DRIVER_NOT_ASSIGNED: 'DRIVER_NOT_ASSIGNED',
  DRIVER_NOT_FOUND: 'DRIVER_NOT_FOUND',
  EMAIL_SEND_FAILED: 'EMAIL_SEND_FAILED',
  FILE_ERROR_READING: 'FILE_ERROR_READING',
  FILE_FAILED_TO_READ_TO_BUFFER: 'FILE_FAILED_TO_READ_TO_BUFFER',
  FILE_INVALID: 'FILE_INVALID',
  FILENAME_NOT_FOUND: 'FILENAME_NOT_FOUND',
  HEALTH_BLOCK_TIME_EXCEEDED: 'HEALTH_BLOCK_TIME_EXCEEDED',
  HEALTH_BLOCKS_NOT_FOUND: 'HEALTH_BLOCKS_NOT_FOUND',
  INVALID_FILENAME: 'INVALID_FILENAME',
  INVALID_USER_ID: 'INVALID_USER_ID',
  LEG_AGENT_ID_IS_REQUIRED: 'LEG_AGENT_ID_IS_REQUIRED',
  LEG_DOCUMENT_ALREADY_EXISTS: 'LEG_DOCUMENT_ALREADY_EXISTS',
  LEG_IS_ALREADY_COLLECTED: 'LEG_IS_ALREADY_COLLECTED',
  LEG_IS_ALREADY_DELIVERED: 'LEG_IS_ALREADY_DELIVERED',
  LEG_LOGISTICS_IMPORTED_AT_IS_REQUIRED: 'LEG_LOGISTICS_IMPORTED_AT_IS_REQUIRED',
  LEG_MUST_BE_COLLECTED_BEFORE_DELIVERING: 'LEG_MUST_BE_COLLECTED_BEFORE_DELIVERING',
  LEG_NOT_FOUND: 'LEG_NOT_FOUND',
  LEG_AGENT_NOT_FOUND: 'LEG_AGENT_NOT_FOUND',
  LEG_DRIVER_NOT_FOUND: 'LEG_DRIVER_NOT_FOUND',
  LEG_POSITION_SHOULD_NOT_BE_NULL: 'LEG_POSITION_SHOULD_NOT_BE_NULL',
  LEG_WITH_ALERT_TYPE_NOT_FOUND: 'LEG_WITH_ALERT_TYPE_NOT_FOUND',
  LOGISTICS_CONNECTOR_PULL_NOT_INVOKED: 'LOGISTICS_CONNECTOR_PULL_NOT_INVOKED',
  LOGISTICS_CONNECTOR_PUSH_NOT_INVOKED: 'LOGISTICS_CONNECTOR_PUSH_NOT_INVOKED',
  LOGISTICS_CONNECTOR_SHIPMENT_NOT_FOUND: 'LOGISTICS_CONNECTOR_SHIPMENT_NOT_FOUND',
  METRICS_CONNECTOR_SHIPMENT_NOT_FOUND: 'METRICS_CONNECTOR_SHIPMENT_NOT_FOUND',
  METRICS_CONNECTOR_GEOFENCE_NOT_FOUND: 'METRICS_CONNECTOR_GEOFENCE_NOT_FOUND',
  METRICS_CONNECTOR_PACKAGE_NOT_FOUND: 'METRICS_CONNECTOR_PACKAGE_NOT_FOUND',
  METRICS_CONNECTOR_PACKAGE_NOT_FOUND_IN_SHIPMENT: 'METRICS_CONNECTOR_PACKAGE_NOT_FOUND_IN_SHIPMENT',
  METRICS_CONNECTOR_PACKAGE_UNKNOWN_THRESHOLDS: 'METRICS_CONNECTOR_PACKAGE_UNKNOWN_THRESHOLDS',
  METRICS_CONNECTOR_SHIPMENT_NOT_FOUND_FOR_PACKAGE: 'METRICS_CONNECTOR_SHIPMENT_NOT_FOUND_FOR_PACKAGE',
  METRICS_CONNECTOR_TRACKER_NOT_FOUND: 'METRICS_CONNECTOR_TRACKER_NOT_FOUND',
  ONLY_ADMINS_CAN_ASSIGN_TRACKERS: 'ONLY_ADMINS_CAN_ASSIGN_TRACKERS',
  ONLY_ADMINS_CAN_UNASSIGN_TRACKERS: 'ONLY_ADMINS_CAN_UNASSIGN_TRACKERS',
  ONLY_AGENTS_CAN_ASSIGN_DRIVERS: 'ONLY_AGENTS_CAN_ASSIGN_DRIVERS',
  ONLY_AGENTS_CAN_UNASSIGN_DRIVERS: 'ONLY_AGENTS_CAN_UNASSIGN_DRIVERS',
  OWNER_ID_IS_REQUIRED: 'OWNER_ID_IS_REQUIRED',
  ORGANIZATION_NOT_FOUND: 'ORGANIZATION_NOT_FOUND',
  PACKAGE_NOT_FOUND: 'PACKAGE_NOT_FOUND',
  PACKAGE_TEMPLATE_NOT_FOUND: 'PACKAGE_TEMPLATE_NOT_FOUND',
  ALERT_TEMPLATE_NOT_FOUND: 'ALERT_TEMPLATE_NOT_FOUND',
  GEOFENCE_NOT_FOUND: 'GEOFENCE_NOT_FOUND',
  PACKAGE_WITH_ALERT_TYPE_NOT_FOUND: 'PACKAGE_WITH_ALERT_TYPE_NOT_FOUND',
  PADDING_EXCEEDS_DATA_LENGTH: 'PADDING_EXCEEDS_DATA_LENGTH',
  PAYLOAD_VALIDATION_ERROR: 'PAYLOAD_VALIDATION_ERROR',
  PDF_BLOB_IS_NOT_AVAILABLE: 'PDF_BLOB_IS_NOT_AVAILABLE',
  POSITION_MUST_BE_AN_ARRAY: 'POSITION_MUST_BE_AN_ARRAY',
  REPOSITORY_INVALID_PARAMS: 'REPOSITORY_INVALID_PARAMS',
  REPOSITORY_OWNER_ID_IS_REQUIRED: 'REPOSITORY_OWNER_ID_IS_REQUIRED',
  REPOSITORY_NO_PERMISSIONS: 'REPOSITORY_NO_PERMISSIONS',
  REQUEST_TRANSACTION_TIMEOUT: 'REQUEST_TRANSACTION_TIMEOUT',
  ROLE_NOT_FOUND: 'ROLE_NOT_FOUND',
  SERVICE_IS_UNDER_MAINTENANCE: 'SERVICE_IS_UNDER_MAINTENANCE',
  SHIPMENT_IS_NOT_TRANSITING: 'SHIPMENT_IS_NOT_TRANSITING',
  SHIPMENT_IS_CANCELLED: 'SHIPMENT_IS_CANCELLED',
  SHIPMENT_IS_DRAFT: 'SHIPMENT_IS_DRAFT',
  SHIPMENT_IS_DELIVERED: 'SHIPMENT_IS_DELIVERED',
  SHIPMENT_MISSING_GEOCODING: 'SHIPMENT_MISSING_GEOCODING',
  SHIPMENT_NOT_FOUND: 'SHIPMENT_NOT_FOUND',
  SHIPMENT_STATUS_UNKOWN: 'SHIPMENT_STATUS_UNKOWN',
  SHIPPER_NOT_FOUND: 'SHIPPER_NOT_FOUND',
  STORAGE_DELETE_NOT_ALLOWED: 'STORAGE_DELETE_NOT_ALLOWED',
  STORAGE_DOWNLOAD_FAILED: 'STORAGE_DOWNLOAD_FAILED',
  STORAGE_FAILED_TO_GET_PRESIGNED_URL: 'STORAGE_FAILED_TO_GET_PRESIGNED_URL',
  STORAGE_FILE_ALREADY_EXISTS: 'STORAGE_FILE_ALREADY_EXISTS',
  STORAGE_FILE_NOT_FOUND: 'STORAGE_FILE_NOT_FOUND',
  TEC4CLOUD_CREDENTIALS_ARE_REQUIRED: 'TEC4CLOUD_CREDENTIALS_ARE_REQUIRED',
  TEC4CLOUD_PROJECT_NOT_FOUND: 'TEC4CLOUD_PROJECT_NOT_FOUND',
  TEC4CLOUD_SHIPMENT_NOT_FOUND: 'TEC4CLOUD_SHIPMENT_NOT_FOUND',
  TRACKER_NOT_FOUND: 'TRACKER_NOT_FOUND',
  TRACKER_OR_PACKAGE_NOT_FOUND: 'TRACKER_OR_PACKAGE_NOT_FOUND',
  TRACKER_SMART_CONTRACT_NOT_FOUND: 'TRACKER_SMART_CONTRACT_NOT_FOUND',
  USER_COMPANY_NAME_AND_NAME_MUST_BE_THE_SAME_FOR_GROUP: 'USER_COMPANY_NAME_AND_NAME_MUST_BE_THE_SAME_FOR_GROUP',
  USER_COMPANY_NAME_AND_NAME_MUST_BE_DIFFERENT_FOR_NON_GROUP: 'USER_COMPANY_NAME_AND_NAME_MUST_BE_DIFFERENT_FOR_NON_GROUP',
  USER_COMPANY_NAME_IS_REQUIRED: 'USER_COMPANY_NAME_IS_REQUIRED',
  USER_CREATE_FAILED_DUE_TO_PERMISSIONS: 'USER_CREATE_FAILED_DUE_TO_PERMISSIONS',
  USER_CREATION_FAILED: 'USER_CREATION_FAILED',
  USER_EMAIL_ALREADY_IN_USE: 'USER_EMAIL_ALREADY_IN_USE',
  USER_NOT_FOUND: 'USER_NOT_FOUND',
  USER_NOT_FOUND_BY_EX_ID: 'USER_NOT_FOUND_BY_EX_ID',
  VALUE_MUST_BE_A_NUMBER: 'VALUE_MUST_BE_A_NUMBER',
  VAULT_SMART_CONTRACT_ERROR_GETTING_DOCUMENT: 'VAULT_SMART_CONTRACT_ERROR_GETTING_DOCUMENT',
  VAULT_SMART_CONTRACT_FILE_NOT_FOUND: 'VAULT_SMART_CONTRACT_FILE_NOT_FOUND',
  VAULT_SMART_CONTRACT_NOT_FOUND: 'VAULT_SMART_CONTRACT_NOT_FOUND',
  WALLET_NOT_FOUND: 'WALLET_NOT_FOUND',
} as const;

export type ErrorCode = ValueOf<typeof ErrorCodes>;

export default ErrorCodes;
