import type { ColumnDef } from '@tanstack/react-table';
import cx from 'clsx';
import { GET, isUUID, truncate } from 'common/helpers.ts';
import useSWR from 'swr';
import type { Alert } from 'types/Alert.ts';
import type { Shipment } from 'types/Shipment.ts';
import type { ShipmentHistoryItem } from 'types/ShipmentHistoryItem.ts';
import { CopyableText } from 'ui/component/CopyableText.tsx';
import { Modal } from 'ui/component/Modal.tsx';
import { Tag } from 'ui/component/Tag.tsx';
import { Tooltip } from 'ui/component/Tooltip.tsx';
import useMe from '#admin/hook/useMe.tsx';
import { AlertTag } from './AlertTag.tsx';
import { Datefrom } from './Datefrom.tsx';
import { Table } from './Table.tsx';

const columns: ColumnDef<ShipmentHistoryItem>[] = [
  {
    header: 'Datetime',
    accessorKey: 'datetime',
    meta: { className: 'justify-left' },
    minSize: 180,
    cell: (info) => {
      const value = info.getValue<string>();
      return <Datefrom blink={false} date={value} aliveInHours={1} />;
    },
  },
  {
    header: 'Type',
    accessorKey: 'type',
    meta: { className: 'justify-left' },
    minSize: 100,
    cell: (info) => {
      const value = info.getValue<ShipmentHistoryItem['type']>();
      return <Tag className={cx(value === 'activity' ? 'blue' : 'red', '!p-2 text-sm')}>{value === 'activity' ? 'Activity' : 'Alert'}</Tag>;
    },
  },
  {
    header: 'Event',
    accessorKey: 'event',
    meta: { className: 'justify-left' },
    minSize: 250,
    cell: (info) => {
      if (info.row.original.type === 'alert') return <AlertTag alert={info.row.original.alert as Alert} />;
      const value = info.getValue<string>();
      const capitalized = value.charAt(0).toUpperCase() + value.slice(1);
      return info.row.original.description ? <Tooltip text={info.row.original.description}>{capitalized}</Tooltip> : capitalized;
    },
  },
  {
    header: 'Resource',
    accessorKey: 'resource_type',
    meta: { className: 'justify-left' },
    minSize: 100,
    cell: (info) => {
      const value = info.getValue<string>();
      if (!value) return '-';
      return value;
    },
  },
  {
    header: 'Resource ID',
    accessorKey: 'resource_id',
    meta: { className: 'justify-left' },
    minSize: 100,
    cell: (info) => {
      const value = info.getValue<string>();
      if (!value) return '-';
      return (
        <CopyableText copyText={value} className="font-mono">
          {isUUID(value) ? truncate(value, 8, '') : value}
        </CopyableText>
      );
    },
  },
  {
    header: 'Generated From',
    accessorKey: 'from',
    meta: { className: 'justify-left' },
    minSize: 180,
    cell: (info) => {
      const value = info.getValue<string>();
      if (!value) return '-';
      return isUUID(value) ? (
        <CopyableText copyText={value} className="font-mono">
          {isUUID(value) ? truncate(value, 8, '') : value}
        </CopyableText>
      ) : (
        <span>{value}</span>
      );
    },
  },
];

type Props = {
  shipment: Shipment;
  onClose?: () => void;
  isOpen: boolean;
};

export const ShipmentHistory: React.FC<Props> = ({ shipment, onClose, isOpen }) => {
  const me = useMe();
  const swr = useSWR([`/shipment/${shipment.id}/history`], GET);

  if (!me) return null;

  return (
    <Modal title={<h4>Shipment History</h4>} key={shipment.id} isOpen={isOpen} onClose={onClose}>
      <div className="flex w-[900px] flex-col gap-8">
        <Table className={cx('max-h-[calc(100vh-375px)]')} columns={columns} swr={swr} defaultSorting={[{ id: 'datetime', desc: true }]} estimateSize={50} overscan={50} />
      </div>
    </Modal>
  );
};
