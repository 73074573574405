import cx from 'clsx';
import type React from 'react';
import type { ALERT_TYPE, Alert } from 'types/Alert.ts';
import { IBatteryEmpty, IClock, IHumidity, ITemperature } from 'ui/component/Icons.tsx';
import { Tag } from 'ui/component/Tag.tsx';

type Props = {
  alert: Alert;
};

export const AlertTag: React.FC<Props> = ({ alert }) => {
  const statusToColor: Record<ALERT_TYPE, string> = {
    battery: 'red',
    temperature: 'red',
    humidity: 'red',
    delay: 'red',
    shock: 'red',
  };
  const { type, max } = alert;
  const isHigh = Number(alert.value) > Number(max);
  const color = statusToColor[type];

  const renderTag = (icon: React.ReactNode, text: string) => (
    <Tag className={cx('!border-0 !p-2 flex items-center justify-center gap-1', color)}>
      {icon}
      <span>{text}</span>
    </Tag>
  );

  switch (type) {
    case 'temperature':
      return renderTag(<ITemperature />, isHigh ? 'High temperature' : 'Low temperature');
    case 'humidity':
      return renderTag(<IHumidity />, isHigh ? 'High humidity' : 'Low humidity');
    case 'battery':
      return renderTag(<IBatteryEmpty />, 'Low battery');
    case 'delay':
      return renderTag(<IClock />, 'Delayed');
    default:
      return <Tag className={cx('!p-2 flex items-center justify-center gap-1', color)}>{type}</Tag>;
  }
};
