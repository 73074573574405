import cx from 'clsx';
import { debounce } from 'es-toolkit';
import React from 'react';
import type { RegisterOptions } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';
import type { IconType } from 'react-icons';
import { IClose, IEye, IEyeOff } from 'ui/component/Icons.tsx';

type Props = {
  children?: React.ReactNode;
  className?: string;
  name: string;
  label?: string;
  placeholder?: string;
  description?: React.ReactNode;
  clearIcon?: boolean;
  RightIcon?: IconType;
  registerOptions?: RegisterOptions;
  handleErrorMessages?: boolean;
  debouncedAutoSubmitForm?: string;
  debouncedAutoSubmitTimeout?: number;
} & React.InputHTMLAttributes<HTMLInputElement>;

const stringToDate = (value: string): string => {
  try {
    return new window.Date(value).toISOString().replace(/\.[0-9]{3}/, '');
  } catch {
    return '';
  }
};

const stringToNumber = (value: string): number => {
  try {
    return Number.parseFloat(value);
  } catch {
    return 0;
  }
};

export const Input: React.FC<Props> = ({
  children,
  className,
  name,
  label,
  description,
  clearIcon = false,
  RightIcon,
  registerOptions = {},
  handleErrorMessages = true,
  debouncedAutoSubmitForm,
  debouncedAutoSubmitTimeout,
  ...otherProps
}) => {
  const { register, formState, setValue } = useFormContext();
  const [type, setType] = React.useState(otherProps.type);

  const EyeIcon = React.useMemo(() => (type === 'password' ? IEye : IEyeOff), [type]);

  React.useEffect(() => {
    setType(otherProps.type);
  }, [otherProps.type]);

  const debouncedOnChange = React.useMemo(() => {
    if (!debouncedAutoSubmitForm || !debouncedAutoSubmitTimeout) return;
    return debounce(() => {
      const form = document.querySelector(debouncedAutoSubmitForm) as HTMLFormElement;
      if (!form) return console.warn(`Input: form with selector ${debouncedAutoSubmitForm} could not be found.`);
      form.requestSubmit();
    }, debouncedAutoSubmitTimeout);
  }, [debouncedAutoSubmitForm, debouncedAutoSubmitTimeout]);

  const setValueAs = React.useMemo(() => {
    if (registerOptions.setValueAs) return registerOptions.setValueAs;
    if (otherProps.type === 'datetime-local') return stringToDate;
    if (otherProps.type === 'date') return stringToDate;
    if (otherProps.type === 'number') return stringToNumber;
    return (value: any) => value;
  }, [otherProps.type, registerOptions.setValueAs]);

  const errors = formState.errors[name];
  const errorContent = errors?.types ? Object.values(errors.types).map((text, index) => <p key={index}>{text}</p>) : null;

  const isDatetime = React.useMemo(() => ['datetime-local', 'datetime'].includes(otherProps.type || ''), [otherProps.type]);

  const onToggleShowPassword = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setType((prevType) => (prevType === 'password' ? 'text' : 'password'));
  };

  const onClear = () => {
    setValue(name, '');
  };

  return (
    <div className={cx('relative flex min-w-max flex-col justify-center', className)} data-testid="control-input">
      {label && (
        <label className="caption mb-2 ml-4 block text-grey-label" htmlFor={`input-${name}`}>
          {label}
        </label>
      )}
      <input
        id={`input-${name}`}
        className={cx(
          'relative h-[44px] w-max min-w-full rounded-full border border-grey-border py-2 pl-5 text-black focus:border-blue disabled:cursor-not-allowed',
          RightIcon ? 'pr-[55px]' : 'px-3',
          errorContent && 'border-red-dark',
          otherProps.disabled && 'bg-gray-100',
          isDatetime && 'invalid:text-grey-light', // this trick is to style the datetime-local placeholder
        )}
        data-testid="input"
        {...register(name, { setValueAs, onChange: debouncedOnChange, ...registerOptions })}
        {...otherProps}
        type={type}
      />

      <div
        className={cx(
          '-translate-y-1/2 absolute z-50 flex size-8 flex-row fill-black hover:cursor-pointer hover:fill-gray-400',
          label ? 'top-[49px]' : 'top-[28px]',
          isDatetime ? 'right-[24px]' : 'right-[10px]',
        )}
      >
        {RightIcon && <RightIcon />}
        {clearIcon && <IClose className={cx(otherProps.type === 'datetime-local' && 'right-[16px]')} onClick={onClear} />}
        {otherProps.type === 'password' && !RightIcon && <EyeIcon onClick={onToggleShowPassword} />}
      </div>

      {description && <span className="ml-3 text-grey">{description}</span>}
      {handleErrorMessages && errorContent && (
        <div data-testid="control-errors" className="red ml-3 flex flex-col gap-1 py-1">
          {errorContent}
        </div>
      )}
      <div className="red mt-1 ml-3">{children}</div>
    </div>
  );
};
