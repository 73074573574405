import * as Sentry from '@sentry/react';
import type { AuthControllerMeResponse } from 'api/auth/Auth.schemas.ts';
import { GET } from 'common/helpers.ts';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useSWR from 'swr';
import { actions } from '#admin/reducer/app.ts';
import type { RootState } from '#admin/store.ts';

export default function useMe() {
  const token = useSelector((state: RootState) => state.app.token);
  const dispatch = useDispatch();
  const { data: me, error } = useSWR<AuthControllerMeResponse>(token ? ['/auth/me'] : null, GET);

  React.useEffect(() => Sentry.setUser(me || null), [me]);

  React.useEffect(() => {
    if (error) dispatch(actions.logout());
  }, [dispatch, error]);

  return me;
}
