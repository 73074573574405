import type { ErrorCode } from './codes.ts';
import type { ErrorMessage } from './messages.ts';

export class BaseError extends Error {
  code: string;
  constructor(code: ErrorCode, message: ErrorMessage) {
    super(message);
    this.code = code;
    Object.setPrototypeOf(this, new.target.prototype);
    Error.captureStackTrace(this, this.constructor);
  }
}

export const isBaseError = (error: unknown): error is BaseError => {
  return error instanceof BaseError;
};
