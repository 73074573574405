import BlockchainError from 'blockchain/BlockchainError.ts';

const ERROR_MESSAGES_BY_TYPE = {
  OrgOrganisationNotFound: 'Organisation not found',
  AuthUserEmailNotFound: 'Credentials are invalid',
  AuthUserOrganisationNotActive: 'Organisation is not active',
  AuthUserNotActive: 'User is not active',
  AuthUserPasswordNotCreated: 'Must go through the "Forgot Password" flow to create your password',
  AuthUserPasswordResetTokenEmpty: 'Password reset token is empty',
  AuthUserCredentialsInvalid: 'Credentials are invalid',
  AuthUserAddressAlreadyInUse: 'Wallet address is already in use',
  AuthUserNotFound: 'User not found',
  AuthInvalidSignature: 'Token is invalid',
  AuthUserPasswordResetTokenExpired: 'Token is expired',
};

export default function getBlockchainErrorMessage(error: any, defaultMessage: string) {
  if (!(error instanceof BlockchainError)) return defaultMessage;

  return ERROR_MESSAGES_BY_TYPE[error.errorName] || defaultMessage;
}
