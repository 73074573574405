import type { ErrorDescription } from 'ethers';

export default class BlockchainError extends Error {
  args: string[];
  errorName: string;

  constructor(error: ErrorDescription) {
    super(error.name);
    this.name = 'BlockchainError';
    this.errorName = error.name;
    this.args = Object.values(error.args);
    Object.setPrototypeOf(this, BlockchainError.prototype);
  }
}
