import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { SWRConfig } from 'swr';
import './axios';
import Router from '#admin/Router.tsx';
import AuthWatcher from '#admin/component/AuthWatcher.tsx';
import { store } from '#admin/store.ts';

const App = () => (
  <Provider store={store}>
    <SWRConfig value={{ revalidateOnFocus: false, shouldRetryOnError: false, dedupingInterval: 1000 }}>
      <AuthWatcher />
      <Router />
    </SWRConfig>
  </Provider>
);

const root = createRoot(document.getElementById('root') as Element);
root.render(<App />);
