import type { Provider, TransactionResponse } from 'ethers';
import { isNonceTooLowError, isWaitTimeoutError } from '../utils/errors.ts';

const wasTransactionReplaced = async (address: string, provider: Provider, txHash: string, txNonce: number): Promise<boolean> => {
  const currentNonce = await provider.getTransactionCount(address, 'pending'); // it works for besu only as it returns the next nonce considering only the pending transactions
  const tx = await provider.getTransaction(txHash);
  return currentNonce >= txNonce && !tx;
};

export const sendTransactionAndRetry = async (
  senderAddress: string,
  sendTransaction: () => Promise<TransactionResponse>,
  provider: Provider,
  config: { retries?: number; timeout?: number; hash?: string; beforeRetry?: () => Promise<void> | void } = {},
): Promise<TransactionResponse> => {
  const retries = config.retries ?? 5;
  const timeout = config.timeout ?? 10_000;
  const hash = config.hash ?? '';
  const beforeRetry = config.beforeRetry ?? (() => {});
  const confirms = 1;

  let txResponse: TransactionResponse | null = null;
  try {
    txResponse = hash ? await provider.getTransaction(hash) : await sendTransaction();
    if (!txResponse) throw new Error('Transaction response not found');

    await txResponse.wait(confirms, timeout);
    return txResponse;
  } catch (error: any) {
    if (retries <= 0) throw error;

    if (isNonceTooLowError(error)) {
      await beforeRetry();
      return await sendTransactionAndRetry(senderAddress, sendTransaction, provider, { retries: retries - 1, timeout });
    }

    if (isWaitTimeoutError(error)) {
      if (!txResponse) throw error;

      const wasReplaced = await wasTransactionReplaced(senderAddress, provider, txResponse.hash, txResponse.nonce);
      if (wasReplaced) {
        await beforeRetry();
        return await sendTransactionAndRetry(senderAddress, sendTransaction, provider, { retries: retries - 1, timeout });
      }
      return await sendTransactionAndRetry(senderAddress, sendTransaction, provider, { retries: retries - 1, timeout, hash: txResponse.hash });
    }

    throw error;
  }
};
