// must be imported directly, if imported from @blockchain/connector, api will not run as there is a top level await
import type { Options } from '@sentry/types';
import BlockchainError from 'blockchain/BlockchainError.ts';
import { isBaseError } from '#common/errors/index.ts';

const commonSentryConfig: Options = {
  beforeSend(event, hint) {
    if (hint?.originalException instanceof BlockchainError) {
      event.extra = { ...event.extra, blockchainError: hint.originalException };
    }
    if (isBaseError(hint?.originalException)) {
      event.extra = { ...event.extra, error: hint.originalException };
    }
    return event;
  },
};

export default commonSentryConfig;
