import type { BlockchainConnector } from 'blockchain/connector.ts';
import { BaseError, errorCodes, errorMessages } from 'common/errors/index.ts';

export default class Wallet {
  public blockchainConnector: BlockchainConnector;

  constructor(blockchainConnector: BlockchainConnector) {
    this.blockchainConnector = blockchainConnector;
  }

  create() {
    const { wallet, privateKey } = this.blockchainConnector.createRandomWallet();
    if (typeof window !== 'undefined') {
      localStorage.setItem('atlas:privateKey', privateKey);
    }
    return wallet;
  }

  get() {
    const privateKey = localStorage.getItem('atlas:privateKey');
    if (!privateKey) throw new BaseError(errorCodes.WALLET_NOT_FOUND, errorMessages.WALLET_NOT_FOUND);
    return this.blockchainConnector.createWallet(privateKey);
  }

  static clear() {
    localStorage.removeItem('atlas:privateKey');
  }
}
