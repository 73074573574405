import { formatDateFrom } from 'common/date.ts';
import type React from 'react';

type Props = {
  date: string;
  aliveInHours?: number;
  blink?: boolean;
};

export const Datefrom: React.FC<Props> = ({ date, aliveInHours, blink = true }) => {
  const { formattedDate, isRecent } = formatDateFrom(date, aliveInHours);
  if (!isRecent) return formattedDate;

  return (
    <span className="flex flex-row">
      <span>{formattedDate}</span>
      {blink && <span className="size-[10px] animate-ping rounded-full bg-sky-400 opacity-75" />}
    </span>
  );
};
