import { IBatteryHalf, IClock, IHumidity, ITemperature } from 'ui/component/Icons.tsx';
import { Tag } from 'ui/component/Tag.tsx';
import Alerts from '#admin/component/Alerts.tsx';

const AlertList = () => {
  return (
    <main className="view-container">
      <section className="mb-4 flex flex-row place-content-between items-center">
        <h4>Alerts</h4>
        <div className="flex items-center justify-center gap-4">
          <span>Alert types:</span>
          <div className="flex gap-4 rounded-2xl p-3">
            <Tag className="red !border-0 !p-1 flex flex-row items-center justify-center gap-2">
              <ITemperature /> Temperature
            </Tag>
            <Tag className="red !border-0 !p-1 flex flex-row items-center justify-center gap-2">
              <IHumidity /> Humidity
            </Tag>
            <Tag className="red !border-0 !p-1 flex flex-row items-center justify-center gap-2">
              <IBatteryHalf /> Battery
            </Tag>
            <Tag className="red !border-0 !p-1 flex flex-row items-center justify-center gap-2">
              <IClock /> Delay
            </Tag>
          </div>
        </div>
      </section>
      <Alerts />
    </main>
  );
};

export default AlertList;
