import type { EthersError } from 'ethers';

export const isNonceTooLowError = (error: EthersError): boolean => {
  const message = (error.message || '').toLowerCase();
  return Boolean(message.match(/nonce (is )?too low/i));
};

export const isWaitTimeoutError = (error: EthersError): boolean => {
  return error.code === 'TIMEOUT';
};
