export const VALIDATION_MESSAGES = {
  oneLowercaseChar: 'One lowercase character',
  oneUppercaseChar: 'One uppercase character',
  oneNumber: 'One number',
  oneSpecialChar: 'One special character',
  minLength: '8 characters minimum',
  signatorName: 'First and Last Name required',
  notEmpty: 'Field is required.',
};

export const oneLowercaseChar = (value: string) => {
  if (!/[a-z]/.test(value)) {
    return VALIDATION_MESSAGES.oneLowercaseChar;
  }
  return undefined;
};

export const oneUppercaseChar = (value: string) => {
  if (!/[A-Z]/.test(value)) {
    return VALIDATION_MESSAGES.oneUppercaseChar;
  }
  return undefined;
};

export const oneNumber = (value: string) => {
  if (!/[0-9]/.test(value)) {
    return VALIDATION_MESSAGES.oneNumber;
  }
  return undefined;
};

export const oneSpecialChar = (value: string) => {
  if (!/[^A-Za-z 0-9]/g.test(value)) {
    return VALIDATION_MESSAGES.oneSpecialChar;
  }
  return undefined;
};

export const minLength = (value: string) => {
  if (value.length < 8) {
    return VALIDATION_MESSAGES.minLength;
  }
  return undefined;
};

export const signatorEmptyOrValid = (value: string) => {
  const isValid = value.trim().length >= 2;
  if (value && !isValid) {
    return VALIDATION_MESSAGES.signatorName;
  }
  return undefined;
};

export const notEmpty = (value: string) => {
  if (value && value.trim().length === 0) {
    return VALIDATION_MESSAGES.notEmpty;
  }
  return undefined;
};
