import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat.js';
import relativeTime from 'dayjs/plugin/relativeTime.js';
import tz from 'dayjs/plugin/timezone.js';
import utc from 'dayjs/plugin/utc.js';
import 'dayjs/locale/en-gb.js';
import 'dayjs/locale/en.js';
import 'dayjs/locale/de.js';
import 'dayjs/locale/es.js';
import 'dayjs/locale/fr.js';
import 'dayjs/locale/it.js';
import 'dayjs/locale/ja.js';
import 'dayjs/locale/pl.js';
import 'dayjs/locale/pt.js';
import 'dayjs/locale/sk.js';
import 'dayjs/locale/zh-cn.js';
import 'dayjs/locale/zh-tw.js';

dayjs.extend(utc);
dayjs.extend(tz);
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

export { dayjs };
