import * as Sentry from '@sentry/react';
import commonSentryConfig from 'common/commonSentryConfig.ts';

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    ...commonSentryConfig,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_APP_ENV,
    release: import.meta.env.VITE_GIT_HASH_VERSION,
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 1.0,
    profilesSampleRate: 1.0,
  });

  Sentry.setTag('application', 'admin');
  Sentry.setTag('fingerprint', window.localStorage.getItem('atlas:fingerprint'));
}
