import { dateToInputValue } from 'common/date.js';
import { MPUT, getRuntimeTimezone } from 'common/helpers.ts';
import { signatorEmptyOrValid } from 'common/validators.ts';
import dayjs from 'dayjs';
import { FormProvider, useForm } from 'react-hook-form';
import useSWRMutation from 'swr/mutation';
import type { Leg } from 'types/Leg.ts';
import { Button } from 'ui/component/Button.tsx';
import { Modal } from 'ui/component/Modal.tsx';
import { Input } from 'ui/control/Input.tsx';
import { Datetime } from '#admin/component/Datetime.js';

type Props = {
  leg: Leg;
  onSave?: () => void;
  onClose?: () => void;
  isOpen: boolean;
};

type FormValues = {
  collection_signator_name: string;
  delivery_signator_name: string;
  origin_actual_datetime: string;
  destination_actual_datetime: string;
};

export const LegEditDialog = ({ leg, onSave, onClose, isOpen }: Props) => {
  const updateLegMutation = useSWRMutation(`/leg/${leg.id}`, MPUT);
  const isLoading = updateLegMutation.isMutating;

  const pendingPOC = leg.poc_required && !leg.poc_url;
  const collectedByDriver = leg.origin_actual_datetime && !leg.origin_manual_actual_datetime;
  const canCollect = !pendingPOC && !collectedByDriver;

  const pendingPOD = leg.pod_required && !leg.pod_url;
  const deliveredByDriver = leg.destination_actual_datetime && !leg.destination_manual_actual_datetime;
  const canDeliver = !pendingPOD && !deliveredByDriver;

  const formMethods = useForm<FormValues>({
    mode: 'onTouched',
    criteriaMode: 'all',
    shouldUnregister: false, // we need the default values to be applied in all cases
    shouldUseNativeValidation: false,
    shouldFocusError: true,
    defaultValues: {
      collection_signator_name: '',
      delivery_signator_name: '',
      origin_actual_datetime: dateToInputValue(leg.origin_actual_datetime) || '',
      destination_actual_datetime: dateToInputValue(leg.destination_actual_datetime) || '',
    },
  });

  const { handleSubmit, formState, watch } = formMethods;

  const [origin_actual_datetime, destination_actual_datetime] = watch(['origin_actual_datetime', 'destination_actual_datetime']);

  const onSubmit = async (values: FormValues) => {
    const origin_actual_datetime = values.origin_actual_datetime ? dayjs(values.origin_actual_datetime).set('seconds', 0).set('milliseconds', 0).toISOString() : null;
    const leg_origin_actual_datetime = leg.origin_actual_datetime ? dayjs(leg.origin_actual_datetime).set('seconds', 0).set('milliseconds', 0).toISOString() : null;

    const destination_actual_datetime = values.destination_actual_datetime
      ? dayjs(values.destination_actual_datetime).set('seconds', 0).set('milliseconds', 0).toISOString()
      : null;
    const leg_destination_actual_datetime = leg.destination_actual_datetime ? dayjs(leg.destination_actual_datetime).set('seconds', 0).set('milliseconds', 0).toISOString() : null;

    const origin_was_changed = leg_origin_actual_datetime !== origin_actual_datetime;
    const destination_was_changed = leg_destination_actual_datetime !== destination_actual_datetime;

    const data = {
      origin_actual_datetime: origin_was_changed ? origin_actual_datetime : undefined,
      destination_actual_datetime: destination_was_changed ? destination_actual_datetime : undefined,
      origin_manual_actual_datetime: origin_was_changed || undefined,
      destination_manual_actual_datetime: destination_was_changed || undefined,
    };

    if (!origin_was_changed && !destination_was_changed) {
      return;
    }

    await updateLegMutation.trigger(data);
    onSave?.();
  };

  return (
    <Modal key={leg.id} title={<h4>Edit Leg</h4>} isOpen={isOpen} onClose={onClose}>
      <FormProvider {...formMethods}>
        <form className="flex max-h-[550px] flex-col gap-2.5 overflow-y-scroll p-2" onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-cols-[100px_min-content] gap-2">
            <span>Leg ID:</span>
            <span>{leg?.logistics_ex_id}</span>
          </div>

          <div className="grid grid-cols-2 grid-rows-2 gap-4">
            <div className="mt-6 flex w-[250px] flex-col">
              <Input
                className="w-full"
                name="origin_actual_datetime"
                label={`Actual Collection datetime (${getRuntimeTimezone(true)})`}
                type="datetime-local"
                disabled={isLoading || !canCollect}
              />
              {leg.origin_tz && (
                <Datetime className="ml-5" timezone={leg.origin_tz} truncateRegion={true}>
                  {origin_actual_datetime}
                </Datetime>
              )}
            </div>

            <div className="mt-6 flex flex-col gap-2">
              <Input
                name="collection_signator_name"
                label="Collection signer name"
                disabled={isLoading || !canCollect}
                registerOptions={{ required: canCollect && leg.poc_required, validate: { signatorEmptyOrValid } }}
              >
                {formState.errors.collection_signator_name?.type === 'required' && 'Field is required.'}
              </Input>
            </div>

            <div className="mt-6 flex flex-col">
              <Input
                name="destination_actual_datetime"
                label={`Actual Delivery datetime (${getRuntimeTimezone(true)})`}
                type="datetime-local"
                disabled={isLoading || !canDeliver}
              />
              {leg.destination_tz && (
                <Datetime className="ml-6" timezone={leg.destination_tz} truncateRegion={true}>
                  {destination_actual_datetime}
                </Datetime>
              )}
            </div>

            <div className="mt-6 flex flex-col gap-2">
              <Input
                name="delivery_signator_name"
                label="Delivery signer name"
                disabled={isLoading || !canDeliver}
                registerOptions={{ required: canDeliver && leg.pod_required, validate: { signatorEmptyOrValid } }}
              >
                {formState.errors.delivery_signator_name?.type === 'required' && 'Field is required.'}
              </Input>
            </div>
          </div>

          <div className="mt-10 flex gap-5">
            <Button type="button" disabled={isLoading} className="blue-outlined" onClick={onClose}>
              BACK
            </Button>
            <Button type="submit" disabled={!formState.isValid || (!canCollect && !canDeliver)} loading={isLoading} className="blue flex items-center justify-center">
              SEND
            </Button>
          </div>
          {updateLegMutation.error && <div className="red mt-2">{updateLegMutation.error}</div>}
        </form>
      </FormProvider>
    </Modal>
  );
};
