import type { ColumnDef, OnChangeFn, SortingState } from '@tanstack/react-table';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import cx from 'clsx';
/**
 * Ref: https://tanstack.com/table/latest/docs/framework/react/examples/virtualized-infinite-scrolling
 */
import React from 'react';
import type { SWRResponse } from 'swr';
import type { SWRInfiniteResponse } from 'swr/infinite';
import { IError, INoResults } from 'ui/component/Icons.tsx';
import { Spinner } from 'ui/component/Spinner.tsx';
import { ControlledCheckbox } from 'ui/control/ControlledCheckbox.tsx';

type Props = {
  className?: string;
  estimateSize: number | ((index: number) => number);
  overscan?: number;
  columns: ColumnDef<any>[];
  defaultSorting?: SortingState;
  swr: SWRInfiniteResponse | SWRResponse;
  pagination?: boolean;
  rowsCount?: number;
  onRowClick?: (data: any, event?: React.MouseEvent) => void;
  onRowMouseEnter?: (data: any, event?: React.MouseEvent) => void;
  onRowMouseLeave?: (data: any, event?: React.MouseEvent) => void;
  onRowSelect?: (data: any) => void;
  onColumnSort?: (data: any) => void;
  wrapHeaders?: boolean;
};

// use this so it's immutable or an infinite render will be caused by the data: data||[]
const NO_DATA = [];

const selectionColumn = {
  id: 'select',
  size: '1',
  header: ({ table }) => (
    <ControlledCheckbox
      type={'checkbox'}
      data-table-no-row-events
      checked={table.getIsAllRowsSelected()}
      indeterminate={table.getIsSomeRowsSelected()}
      onChange={table.getToggleAllRowsSelectedHandler()}
    />
  ),
  cell: ({ row }) => (
    <ControlledCheckbox type={'checkbox'} data-table-no-row-events checked={row.getIsSelected()} disabled={!row.getCanSelect()} onChange={row.getToggleSelectedHandler()} />
  ),
};

export const Table: React.FC<Props> = ({
  className,
  columns,
  swr,
  pagination = false,
  rowsCount,
  estimateSize,
  defaultSorting = [],
  overscan,
  onRowClick,
  onRowMouseEnter,
  onRowMouseLeave,
  onRowSelect,
  onColumnSort,
  wrapHeaders = false,
}) => {
  const [sorting, setSorting] = React.useState<SortingState>(defaultSorting);
  const tableContainerRef = React.useRef<HTMLDivElement>(null);
  const [rowSelection, onRowSelectionChange] = React.useState({});

  /**
   * We have to make a distinction between the useSWR and useSWRInfinite hooks to properly:
   * 1) data: flat array (results) vs array of arrays (pages of results)
   * 2) pagination: hide "next page" button vs show "next page" button
   */
  const { data: datasetOrPages = NO_DATA, isValidating, error, size, setSize } = swr as SWRInfiniteResponse;
  const data: any[] = React.useMemo(() => datasetOrPages?.flat() ?? NO_DATA, [datasetOrPages]);

  if (pagination && data.length !== new Set(data.map((item) => item.id)).size) {
    console.error('Pagination is faulty: duplicated or missing items were found in data array.');
  }

  const fetchNextPage = React.useCallback(() => {
    if (!setSize) return Function.prototype;
    return setSize(size + 1);
  }, [size, setSize]);

  const columnsWithSelection = React.useMemo(() => {
    // if user provided onRowSelect handler then enable row selection
    if (onRowSelect) return [selectionColumn, ...columns];
    else return columns;
  }, [onRowSelect, columns]);

  const onSortingChange: OnChangeFn<SortingState> = (updaterOrValue) => {
    const nextSorting: SortingState = typeof updaterOrValue === 'function' ? updaterOrValue(sorting) : updaterOrValue;
    setSorting(nextSorting);
    if (onColumnSort) onColumnSort(nextSorting);
  };

  const table = useReactTable({
    data,
    columns: columnsWithSelection as ColumnDef<any, any>[],
    state: { rowSelection, sorting },
    manualSorting: setSize !== undefined,
    enableRowSelection: true,
    onRowSelectionChange,
    onSortingChange,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const { rows } = table.getRowModel();

  const virtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => tableContainerRef.current,
    estimateSize: typeof estimateSize === 'number' ? () => estimateSize : estimateSize,
    overscan,
  });

  const virtualRows = virtualizer.getVirtualItems();

  const onRowClickHandler = React.useCallback(
    (row) => (event: React.MouseEvent) => {
      if (!onRowClick) return;
      else if ((event.target as HTMLElement).hasAttribute('data-table-no-row-events')) return event.stopPropagation();
      else onRowClick(row.original, event);
    },
    [onRowClick],
  );

  const onRowMouseEnterHandler = React.useCallback(
    (row) => (event: React.MouseEvent) => {
      if (!onRowMouseEnter) return;
      else if ((event.target as HTMLElement).hasAttribute('data-table-no-row-events')) return event.stopPropagation();
      onRowMouseEnter(row.original, event);
    },
    [onRowMouseEnter],
  );

  const onRowMouseLeaveHandler = React.useCallback(
    (row) => (event: React.MouseEvent) => {
      if (!onRowMouseLeave) return;
      else if ((event.target as HTMLElement).hasAttribute('data-table-no-row-events')) return event.stopPropagation();
      onRowMouseLeave(row.original, event);
    },
    [onRowMouseLeave],
  );

  /** TODO: solve flickering in a better way. */
  const isFirstLoading =
    pagination === false
      ? // if we don't have pagination check if loading or data undefined (meaning we are waiting the count request finishing before firing our list request)
        isValidating || data === NO_DATA
      : // if we have pagination, don't flicker when loading new page
        rowsCount === undefined || (isValidating && size <= 1);
  const isRefetchLoading = isValidating && (!size || size > 1); // if we have pagination, don't flicker when loading new page
  const isErrored = !isValidating && !!error;
  const noResults = !isValidating && !isErrored && data.length === 0;

  if (isFirstLoading) {
    return (
      <div className={cx(className, 'flex items-center justify-center')}>
        <div className="flex w-max flex-col items-center justify-center p-12">
          <Spinner />
        </div>
      </div>
    );
  }

  if (noResults) {
    return (
      <div className={cx(className, 'flex items-center justify-center')}>
        <div className="flex w-max flex-col items-center justify-center p-12">
          <INoResults size="48" />
          <p>No results found.</p>
        </div>
      </div>
    );
  }

  if (isErrored) {
    return (
      <div className={cx(className, 'flex items-center justify-center')}>
        <div className="flex w-max flex-col items-center justify-center p-12">
          <IError size="48" />
          <p title={error?.response?.data?.message ?? error?.message}>Oops, an error occurred!</p>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={cx('relative overflow-auto border-b border-b-grey-border bg-white', className)} ref={tableContainerRef}>
        <table data-role="table" className="w-full table-fixed">
          <thead data-role="thead" className="sticky top-0 z-20 m-0 bg-white shadow-[inset_0_-1px_0] shadow-slate-300">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr data-role="tr" key={headerGroup.id} className="flex w-full place-content-between">
                {headerGroup.headers.map((header) => (
                  <th
                    data-role="th"
                    key={header.id}
                    colSpan={header.colSpan}
                    className={cx('flex border-grey-border p-3 text-left align-top', !wrapHeaders && 'truncate', header.column.columnDef.meta?.className)}
                    style={{ width: header.getSize() }}
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        className={cx('font-normal text-gray-500', header.column.getCanSort() && 'cursor-pointer select-none')}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: ' ↑',
                          desc: ' ↓',
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody data-role="tbody" className="relative z-10 block" style={{ height: `${virtualizer.getTotalSize()}px` }}>
            {virtualRows.map((virtualRow, index) => {
              const row = rows[virtualRow.index];
              return (
                <tr
                  data-role="tr"
                  ref={(node) => virtualizer.measureElement(node)} // measure dynamic row height
                  key={index}
                  data-index={virtualRow.index} // needed for dynamic row height measurement
                  className="absolute flex w-full cursor-pointer place-content-between border-grey-border border-b align-top last:border-b-0 hover:bg-gray-100"
                  style={{ transform: `translateY(${virtualRow.start}px)` }}
                  onClick={onRowClickHandler(row)}
                  onMouseEnter={onRowMouseEnterHandler(row)}
                  onMouseLeave={onRowMouseLeaveHandler(row)}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      data-role="td"
                      key={cell.id}
                      className={cx('flex items-center truncate p-3', cell.column.columnDef.meta?.className)}
                      style={{ width: cell.column.getSize() }}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {!isErrored && (
        <div className="mx-auto mt-4 flex flex-row items-end justify-center gap-2">
          <span>
            Showing {data?.length ?? 0} of {rowsCount} results.
          </span>
          {pagination && rowsCount && rows.length < rowsCount && (
            <>
              <button className="text-blue-600 underline disabled:cursor-default disabled:text-grey-label" disabled={isValidating} onClick={fetchNextPage}>
                Load more.
              </button>
              {isRefetchLoading && <Spinner size={18} />}
            </>
          )}
        </div>
      )}
    </>
  );
};

export const getSWRKeyForPage =
  (path: string, pageSize: number, rowsCount: number, filters: Record<string, any>, sorters: SortingState | null, ...rest: any[]) =>
  (pageIndex: number) => {
    // don't fetch if we don't have the total number of rows
    if (typeof rowsCount !== 'number') return null;
    // calculate the offset based on the page index
    const offset = pageIndex * pageSize;
    // calculate the sort
    const sort = tableSortersToRepositorySort(sorters);
    // add the limit and offset to the filters (rest can contain any other param that should be part of the key and used by GET/POST fetchers)
    const key = [path, { ...filters, sort, limit: pageSize, offset }, ...rest];
    // console.log(`fetching limit=${pageSize} offset=${offset}`);
    return key;
  };

export const tableSortersToRepositorySort = (sorters: SortingState | null) => {
  if (!sorters || !sorters.length) return undefined;
  const result = sorters.map(({ id, desc }) => [id, desc ? 'desc' : 'asc']);
  return result;
};
