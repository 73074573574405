import ErrorMessages from 'common/errors/messages.ts';
import { MPOST, addressToBeSigned } from 'common/helpers.ts';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import useSWRMutation from 'swr/mutation';
import { Button } from 'ui/component/Button.tsx';
import { Input } from 'ui/control/Input.tsx';
import useBlockchain from '#admin/hook/useBlockchain.tsx';
import { actions } from '#admin/reducer/app.ts';
import type { AppDispatch } from '#admin/store.ts';

type FormValues = {
  email: string;
  password: string;
};

type Props = {
  onSuccess: string;
};

const Auth: React.FC<Props> = ({ onSuccess }) => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { auth, wallet } = useBlockchain();

  const formMethods = useForm<FormValues>({
    mode: 'onTouched',
    criteriaMode: 'all',
    shouldUnregister: true,
    shouldUseNativeValidation: false,
    shouldFocusError: true,
    defaultValues: { email: '', password: '' },
  });
  const { handleSubmit, formState } = formMethods;

  const { trigger, error } = useSWRMutation<FormValues>('/auth/login', MPOST);

  const onSubmit = async (values: FormValues) => {
    if (!auth) return;

    const signature = await auth.login(values.email, values.password);
    // we need to encode the signature to base64 to overcome WAF blocking hexadecimal strings in urls and payloads
    const encodedSignature = btoa(signature);

    // @ts-ignore
    const token: string = await trigger({ email: values.email, signature: encodedSignature });
    dispatch(actions.login({ token }));
    navigate(onSuccess);
  };

  React.useEffect(() => {
    const token = searchParams.get('token');
    if (token) {
      dispatch(actions.login({ token }));
      navigate(onSuccess);
    }
  }, [searchParams, dispatch, navigate, onSuccess]);

  const onGoogleSignIn = async () => {
    if (!wallet) return;

    const ssoUserWallet = wallet.create();
    const ssoUserWalletAddress = await ssoUserWallet.getAddress();
    const signature = await ssoUserWallet.signMessage(addressToBeSigned(ssoUserWalletAddress));
    const url = new URL('/auth/saml-connect', import.meta.env.VITE_API_URL).href;
    const data = btoa(JSON.stringify({ address: ssoUserWalletAddress, signature }));
    const params = new URLSearchParams({ data }).toString();

    window.location.href = `${url}?${params}`;
  };

  return (
    <main
      className="fixed top-0 left-0 h-screen w-screen overflow-auto"
      style={{ background: 'linear-gradient(111.34deg, #00CBE0 0%, #006BFA 33.33%, #1B1CDD 66.67%, #640BED 100%)' }}
    >
      <FormProvider {...formMethods}>
        <form
          className="paper !absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 m-auto w-[480px] rounded-xl border pt-16"
          autoCorrect={'off'}
          autoComplete={'off'}
          onSubmit={handleSubmit(onSubmit)}
          noValidate={true}
        >
          <div className="flex flex-col items-center gap-4 p-8">
            <div className="mb-6 flex w-full items-center justify-center">
              <img src={'/atlas-path-logo.svg'} width={300} />
            </div>
            <a
              onClick={onGoogleSignIn}
              className="flex h-12 w-full cursor-pointer flex-row place-content-center place-items-center gap-2 rounded-md border border-grey-border p-2 transition-all hover:scale-105 hover:border-slate-500"
            >
              <img className="inline h-auto w-[32px]" src="/google.webp" />
              <span>Sign in with Google</span>
            </a>
            <span className="my-4 inline text-center text-gray-500">
              Log in with your credentials <br /> if you don&apos;t have a CRYOPDP account.
            </span>
            <div className="flex w-full flex-col items-center gap-4">
              <Input
                className="w-full"
                name={'email'}
                label={'Email address'}
                type={'email'}
                registerOptions={{
                  validate: { required: (value) => (value ? undefined : "Email can't be empty.") },
                }}
              />
              <Input
                className="w-full"
                name={'password'}
                label={'Password'}
                type={'password'}
                registerOptions={{ validate: { required: (value) => (value ? undefined : "Password can't be empty.") } }}
              />
              <Button className="blue mt-4 gap-1" type={'submit'} disabled={!formState.isValid} loading={formState.isSubmitting}>
                LOGIN
              </Button>
            </div>
            {formState.isSubmitted && !formState.isSubmitSuccessful && (
              <section className="mt-4 grid grid-flow-col justify-center text-center text-red-700">
                {typeof error?.response?.data?.message === 'string' ? error.response.data?.message : ErrorMessages.AUTH_INVALID_CREDENTIALS}
              </section>
            )}
            <Link to="/password-request" className="button-link flex h-10 w-full items-center justify-center">
              Forgot password?
            </Link>
          </div>
        </form>
      </FormProvider>
    </main>
  );
};

export default Auth;
