import { GET } from 'common/helpers.ts';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import type { Tracker } from 'types/Tracker.ts';
import { Breadcrumb } from 'ui/component/Breadcrumb.tsx';
import { Spinner } from 'ui/component/Spinner.tsx';
import { TrackerDetails } from '#admin/component/TrackerDetails.tsx';

const TrackerDetail = () => {
  const { id: trackerId } = useParams();
  const { data: tracker } = useSWR<Tracker>([`/tracker/${trackerId}`], GET);

  if (!tracker) return <Spinner centered={true} />;

  return (
    <main className="view-container">
      <Breadcrumb className="mb-6" items={[{ label: 'Trackers', path: '/trackers' }, { label: 'Tracker details' }]} />
      <TrackerDetails tracker={tracker} />
    </main>
  );
};

export default TrackerDetail;
